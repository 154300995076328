import React, { ChangeEvent, FC, Fragment, useCallback, useState } from "react";
import { Button, Grid, Slider, Typography } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
// @ts-ignore
import Cropper, { Area } from "react-easy-crop";
import * as path from "path";
import { FilterType } from "./constants";
import getCroppedImg from "./functions/crop-img";
import useImageUploadStyles from "./styles";
import CloudUpload from "@material-ui/icons/CloudUpload";

// import dynamic from "next/dynamic";
// import heic2any from "heic2any";
// @ts-ignore
// const heic2any = dynamic(() => import("heic2any"));

const MAX_DIMENSION = 2048;

export interface ImageUploadDialogProps {
    defaultAspectRatio?: number;
    // Will be called after "upload" is completed to indicate the progression
    onClose?(): void;
    uploadImage(data: { png: Blob }, fileName: string): void;
    disabled?: boolean;
}

function replaceUmlaut(str) {
    let replacedString = str;
    for (var i = 0; i < replacedString.length; i++) {
        if (str.charCodeAt(i) === 776) {
            replacedString = replacedString.replace(str.charAt(i), "e");
        } else if (str.charCodeAt(i) === 223) {
            replacedString = replacedString.replace(str.charAt(i), "ss");
        } else if (str.charCodeAt(i) === 769) {
            replacedString = replacedString.replace(str.charAt(i), "e");
        }
    }
    return replacedString.replace(/[^a-zA-Z0-9]/g, "");
}

export const ImageUploadDialog: FC<ImageUploadDialogProps> = ({ defaultAspectRatio, uploadImage, disabled, ...props }) => {
    const classes = useImageUploadStyles();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1.1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [srcImage, setSrcImage] = useState("");
    const [srcImageType, setSrcImageType] = useState("");
    const [isCropShapeRound, setCropShapeRound] = useState(false);
    const [croppedImageSrc, setCroppedImageSrc] = useState("");
    const [croppedImage, setCroppedImage] = useState<Blob | undefined>();
    const [originalAspectRatio, setOriginalAspectRatio] = useState<number>(defaultAspectRatio ?? 1);
    const [aspectRatio, setAspectRatio] = useState<number>(defaultAspectRatio ?? 1);

    const [contrast, setContrast] = useState(100);
    const [hue, setHue] = useState(0);
    const [brightness, setBrightness] = useState(100);
    const [saturation, setSaturation] = useState(100);
    const [sepia, setSepia] = useState(0);
    const [invert, setInvert] = useState(0);
    const [fileName, setFileName] = useState("");

    const [multiSrcImage, setMultiSrcImage] = useState([]);
    const [multiAspectRatio, setMultiAspectRatio] = useState([]);
    const [multiFileName, setMultiFileName] = useState([]);
    const [multiImageType, setMultiSrcImageType] = useState([]);
    const [count, setCount] = useState(1);

    // const imgStyle = {
    //     filter: ` contrast(${contrast}%) hue-rotate(${hue}deg) brightness(${brightness}%) saturate(${saturation}%) sepia(${sepia}%) invert(${invert}%)`
    // };

    // const handleFilterChange = (filterType: FilterType, value: number) => {
    //     switch (filterType) {
    //         case FilterType.CONTRAST:
    //             setContrast(value);
    //             break;
    //         case FilterType.HUE:
    //             setHue(value);
    //             break;
    //         case FilterType.BRIGHTNESS:
    //             setBrightness(value);
    //             break;
    //         case FilterType.SATURATION:
    //             setSaturation(value);
    //             break;
    //         case FilterType.SEPIA:
    //             setSepia(value);
    //             break;
    //         case FilterType.INVERT:
    //             setInvert(value);
    //             break;
    //     }
    // };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    // const showCroppedImage = useCallback(async () => {
    //     try {
    //         const imgStyle = {
    //             filter: ` contrast(${contrast}%) hue-rotate(${hue}deg) brightness(${brightness}%) saturate(${saturation}%) sepia(${sepia}%) invert(${invert}%)`
    //         };
    //         if (croppedAreaPixels !== null) {
    //             const croppedImageResult: { png: Blob } = await getCroppedImg(
    //                 srcImage,
    //                 croppedAreaPixels,
    //                 imgStyle,
    //                 srcImageType,
    //                 rotation,
    //                 isCropShapeRound,
    //                 MAX_DIMENSION
    //             );
    //             // console.log("done", { croppedImage });
    //             // @ts-ignore
    //             setCroppedImageSrc(URL.createObjectURL(croppedImageResult.png));
    //             setCroppedImage(croppedImageResult.png);
    //         }
    //     } catch (e) {
    //         // console.error(e);
    //     }
    // }, [croppedAreaPixels, rotation]);

    const onReset = () => {
        setCrop({ x: 0, y: 0 });
        setRotation(0);
        setZoom(2);
        setCroppedAreaPixels(null);
        setCropShapeRound(false);
        setAspectRatio(originalAspectRatio);

        setContrast(100);
        setHue(0);
        setBrightness(100);
        setSaturation(100);
        setSepia(0);
        setInvert(0);
    };

    const onDelete = () => {
        setSrcImage("");
        setSrcImageType("");
        setCroppedImage(undefined);
        setCroppedImageSrc("");
        onReset();
    };

    const onClose = () => {
        onDelete();
        props.onClose();
    };

    const onSave = async () => {
        setCount(count + 1);
        if (srcImage === "" || croppedAreaPixels === null) return;

        // We create an image with the current design settings
        const imgStyle = {
            filter: ` contrast(${contrast}%) hue-rotate(${hue}deg) brightness(${brightness}%) saturate(${saturation}%) sepia(${sepia}%) invert(${invert}%)`
        };
        const croppedImageResult: { png: Blob } = await getCroppedImg(
            srcImage,
            croppedAreaPixels,
            imgStyle,
            srcImageType,
            rotation,
            isCropShapeRound,
            MAX_DIMENSION
        );

        // const uploadFilename = fileName.slice(0, fileName.lastIndexOf("."));
        uploadImage(croppedImageResult, fileName);

        if (multiSrcImage.length > 1 && count + 1 <= multiSrcImage.length) {
            onReset();
            const multiSrcImageOrdered = [...multiSrcImage].reverse();
            const multiImageTypeOrdered = [...multiImageType].reverse();
            const multiFileNameOrdered = [...multiFileName].reverse();
            const multiAspectRatioOrdered = [...multiAspectRatio].reverse();
            setSrcImage(multiSrcImageOrdered[count]);
            setSrcImageType(multiImageTypeOrdered[count]);
            setFileName(multiFileNameOrdered[count]);
            setAspectRatio(multiAspectRatioOrdered[count]);
        } else onClose();
    };

    // const handleShapeSwitch = () => {
    //     setCropShapeRound(!isCropShapeRound);
    // };

    // const handleAspectRatio = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    //     setAspectRatio(event.target.value as number);
    //     if ((event.target.value as number) !== 1) {
    //         setCropShapeRound(false);
    //     }
    // };

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file: any) => {
            const extension = path.extname(file.name);

            if (
                extension !== ".jpeg" &&
                extension !== ".jpg" &&
                extension !== ".JPG" &&
                extension !== ".png" &&
                extension !== ".gif" &&
                extension !== ".PNG"
                // extension !== ".heic" &&
                // extension !== ".HEIC"
            ) {
                console.log("Du kannst nur Dateien mit den Dateinendungen .jpeg, .jpg, .png, .PNG, .gif hochladen");
                return;
            }

            const reader = new FileReader();

            // Note: remove heic support as it does not copy metadata properly
            // (async function convertFromHeic() {
            //     if (extension === ".heic" || extension === ".HEIC") {
            //         const heicToPngResult = await heic2any({
            //             blob: file,
            //             toType: "image/png",
            //             quality: 1
            //         });
            //         reader.readAsArrayBuffer(heicToPngResult as Blob);
            //     }
            // })();

            reader.onabort = () => console.log("Das Hochladen wurde abgebrochen");
            reader.onerror = () => console.log("Das Bild konnte nicht hochgeladen werden");
            reader.onload = () => {
                setFileName(replaceUmlaut(file.name));
                setMultiFileName(oldArray => [...oldArray, replaceUmlaut(file.name)]);
                const binaryStr = reader.result;
                const arrayBufferView = new Uint8Array(binaryStr as ArrayBuffer);

                // Figure out mimetype
                let mimetype: string;
                switch (extension) {
                    case ".jpeg":
                    case ".jpg":
                        mimetype = "image/jpeg";
                        break;
                    case ".JPG":
                        mimetype = "image/jpeg";
                        break;
                    case ".gif":
                        mimetype = "image/gif";
                        break;
                    case ".png":
                    case ".PNG":
                        mimetype = "image/png";
                        break;
                    // case ".heic":
                    // case ".HEIC":
                    //     mimetype = "image/png";
                    //     break;
                    default:
                        // Should never happen
                        mimetype = "image/jpeg";
                        break;
                }
                setSrcImageType(mimetype);
                setMultiSrcImageType(oldArray => [...oldArray, mimetype]);

                const blob = new Blob([arrayBufferView], { type: mimetype });
                const urlCreator = window.URL;
                const imageUrl = urlCreator.createObjectURL(blob);

                // Get Image Dimensions
                const image = new Image();

                image.onload = () => {
                    // Get Aspect Ratio
                    const ratio = defaultAspectRatio ?? image.width / image.height;
                    setOriginalAspectRatio(ratio);
                    setAspectRatio(ratio);
                    setMultiAspectRatio(oldArray => [...oldArray, ratio]);

                    setSrcImage(imageUrl);
                    setMultiSrcImage(oldArray => [...oldArray, imageUrl]);
                };

                image.onerror = () => {
                    console.log("Das Bild konnte nicht hochgeladen werden");
                };

                image.src = imageUrl;
            };

            reader.readAsArrayBuffer(file);
        });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <Fragment>
            {srcImage !== "" && (
                <div>
                    <Grid container justifyContent={"center"} direction={"column"} spacing={2}>
                        <Grid container justifyContent={"center"} className={classes.cropContainer} item>
                            <Cropper
                                image={srcImage}
                                crop={crop}
                                rotation={rotation}
                                zoom={zoom}
                                aspect={aspectRatio}
                                onCropChange={setCrop}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                                cropShape={isCropShapeRound ? "round" : "rect"}
                                style={{
                                    containerStyle: {
                                        position: "relative",
                                        width: 400,
                                        height: 320
                                    },
                                    cropAreaStyle: {
                                        width: 400,
                                        height: 400
                                    }
                                }}
                            />
                        </Grid>
                        <Grid className={classes.controlContainer} container justifyContent={"center"} direction={"row"} item>
                            <Grid className={classes.controls} container item spacing={2} xs={12}>
                                <Grid item xs={8}>
                                    <Grid className={classes.controllerContainer} item>
                                        <Typography variant={"h4"}>{"Zoom"}</Typography>
                                        <Slider
                                            value={zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            marks={[{ value: 1 }, { value: 1.5 }, { value: 2 }, { value: 2.5 }, { value: 3 }]}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="Zoom"
                                            onChange={(e, zoom) => setZoom(zoom as number)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={"center"}>
                        <Button className={classes.trashButton} aria-label="reset" onClick={onReset}>
                            {"Zurücksetzen"}
                        </Button>
                        <Button className={classes.trashButton} aria-label="delete" onClick={onDelete}>
                            {"Verwerfen"}
                        </Button>
                        <Button className={classes.saveButton} aria-label="save" onClick={onSave}>
                            {"Speichern"}
                        </Button>
                    </Grid>
                </div>
            )}
            {srcImage === "" && (
                <div className={classes.dropZoneContainer} {...getRootProps()}>
                    <input {...getInputProps()} disabled={disabled} />
                    {isDragActive ? (
                        <Typography>{"Ziehen Sie ein Bild hier rein..."}</Typography>
                    ) : (
                        <Grid container justifyContent={"space-around"} direction={"column"} alignItems={"center"} style={{ width: "100%" }}>
                            <Typography className={classes.uploadTitle} align={"center"}>
                                {"Klicken Sie auf die Fläche, um eine Datei auszuwählen oder ziehen Sie diese in das Feld"} <br />
                            </Typography>
                            <CloudUpload className={classes.uploadIcon} />
                            <Typography align={"center"} className={classes.uploadSubtitle}>
                                {"Diese Dateiformate werden unterstützt: .jpeg, .jpg, .JPG, .png, .gif."} <br />
                            </Typography>
                        </Grid>
                    )}
                </div>
            )}
            <img alt={""} src={croppedImageSrc as string} />
        </Fragment>
    );
};
