import produce, { Draft } from "immer";
import { useCallback, useState } from "react";

type CallbackFunction<T> = (d: Draft<T>) => Draft<T> | void | undefined;

/**
 * Basically the normal React.useState, but supercharged
 * so that the setState function uses "immer" for deeply nested (immutable) updates
 **/
export const useImmerState = <T extends object>(defaultValue: T) => {
    const [state, setState] = useState<T>(defaultValue);

    const setImmerState = useCallback(
        (valueOrCallback: T | CallbackFunction<T>) => {
            // @ts-ignore
            if (typeof valueOrCallback === "function") setState(produce(valueOrCallback));
            else setState(valueOrCallback);
        },
        [setState]
    );

    return [state, setImmerState] as const;
};
