import { FC } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import salesProgressStyles from "../styles";
import { setProgressColor } from "../helpers-progress";

const useStyles = makeStyles(salesProgressStyles);

interface TimelineItemProps {
    isButtons?: boolean;
    text: string;
    progress?: string;
}

const TimelineItem: FC<TimelineItemProps> = ({ isButtons, text, children, progress = "not-done" }) => {
    const classes = useStyles({ isButtons });

    return (
        <li className={classes.sectionItem}>
            <div className={classes.dashedBorder} style={{ borderLeft: `2px dashed ${setProgressColor(progress)}` }} />
            <div className={classes.innerWrapper}>
                <div className={classes.circle} style={{ backgroundColor: setProgressColor(progress) }} />
                <Typography className={classes.itemName}>{text}</Typography>
            </div>

            {children}
        </li>
    );
};

export default TimelineItem;
