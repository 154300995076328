import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useFilledInputStyles = makeStyles(theme =>
    createStyles({
        filledInput: {
            width: "100%",
            minHeight: "46px",
            margin: theme.spacing(1, 0, 1, 0),
            "@media (max-width: 1024px)": {
                "@media (orientation:landscape)": { maxWidth: 350 }
            },
            "&:hover": {
                background: "transparent"
                // border: "none"
                // color: "transparent"
            }
        },
        errorMessage: {
            fontSize: 12,
            color: "red",
            position: "absolute",
            left: 0,
            // transform: "translateX(-50%)",
            bottom: -16,
            whiteSpace: "nowrap"
        },
        menu: {
            textTransform: "capitalize",
            background: "#FFFFFF",
            height: 40,
            paddingTop: 0,
            paddingBottom: 0
        },
        label: { zIndex: 1, marginLeft: 15, opacity: 0.3, marginTop: 15, pointerEvents: "none", position: "absolute", fontWeight: 400 }
    })
);
