import { FilledInput as MuiFilledInput, FilledInputProps, Typography } from "@material-ui/core";
import { forwardRef } from "react";
import { useFilledInputStyles } from "./styles";

type OnpreoFilledInputProps = FilledInputProps & {
    label?: string;
    error?: { message: string };
};

export const FilledInput = forwardRef<HTMLInputElement, OnpreoFilledInputProps>(({ label, error, ...props }, ref) => {
    const classes = useFilledInputStyles();

    return (
        <div className={props.className}>
            {label && <Typography style={{ fontWeight: 600, fontSize: "14px", textAlign: "start" }}>{label}</Typography>}
            <div style={{ position: "relative" }}>
                <MuiFilledInput
                    {...props}
                    className={classes.filledInput}
                    ref={ref}
                    /* type={name} */
                />
                {error !== undefined && <Typography className={classes.errorMessage}>{error.message}</Typography>}
            </div>
        </div>
    );
});
