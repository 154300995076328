import { createStyles } from "@material-ui/core";

const CIRCLE_SIZE = 16;
const CIRCLE_WITH_ICON_SIZE = 33;

const salesProgressStyles = theme =>
    createStyles({
        detailsCard: {
            margin: "0 auto",
            width: "100%",
            boxShadow: "0px 0px 32px rgba(136, 152, 170, 0.15)",
            borderRadius: "6px",
            background: "#FFFFFF",
            position: "relative"
        },
        detailsHeaderGrid: {
            marginLeft: theme.spacing(0)
        },
        detailsHeaderText: {
            color: "#32325D",
            fontWeight: 600,
            fontSize: 17
        },
        divider: {
            position: "absolute",
            width: "100%",
            marginLeft: theme.spacing(-3),
            marginTop: theme.spacing(2)
        },
        timelineList: { marginTop: theme.spacing(5) },
        iconWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: CIRCLE_WITH_ICON_SIZE,
            height: CIRCLE_WITH_ICON_SIZE,
            marginRight: 28,

            backgroundColor: "#E9ECEF",
            borderRadius: 100,
            [theme.breakpoints.down("sm")]: {
                marginRight: 10
            }
        },
        textIconWrapper: {
            display: "flex",
            alignItems: "center",
            paddingBottom: 22,
            position: "relative"
        },
        dashedBorderWithIcon: {
            borderLeft: "2px dashed #E9ECEF",
            position: "absolute",
            top: CIRCLE_WITH_ICON_SIZE,
            bottom: 0,
            left: 16,
            height: `calc(100% - ${CIRCLE_WITH_ICON_SIZE})`
        },
        sectionList: {
            margin: 0,
            padding: 0,
            listStyle: "none"
        },
        sectionItem: {
            display: "block",
            position: "relative",

            "&:not(:last-child)": {
                paddingBottom: ({ isButtons }: { isButtons: boolean }) => (isButtons ? 24 : 20)
            },
            "&:last-child": {
                paddingBottom: ({ isButtons }: { isButtons: boolean }) => (isButtons ? 24 : 42)
            }
        },
        dashedBorder: {
            borderLeft: "2px dashed #E9ECEF",
            position: "absolute",
            top: CIRCLE_SIZE,
            bottom: 0,
            left: 16,
            height: `calc(100% - ${CIRCLE_SIZE})`
        },
        innerWrapper: {
            display: "flex",
            alignItems: "center"
        },
        lastItem: {
            display: "flex",
            alignItems: "center"
        },
        circle: {
            width: CIRCLE_SIZE,
            height: CIRCLE_SIZE,
            backgroundColor: "#E9ECEF",
            borderRadius: 100,
            marginLeft: 9,
            marginRight: 36
        },
        sectionName: {
            fontWeight: 600,
            fontSize: 13,
            lineHeight: 1.38,
            color: "#32325D",
            [theme.breakpoints.down("sm")]: {
                fontSize: 9
            }
        },
        itemName: {
            fontWeight: 600,
            fontSize: 13,
            lineHeight: 1.38,
            color: "#8898AA",
            [theme.breakpoints.down("sm")]: {
                fontSize: 10
            }
        },

        buttonBlue: {
            marginLeft: 60,
            marginTop: 22,
            backgroundColor: "#0071E3",
            border: "1px solid transparent",

            "&:hover": {
                backgroundColor: "#3898EC"
            }
        },
        buttonPrimary: {
            marginLeft: 60,
            marginTop: 22
        },
        interestedBuyerButton: {
            marginLeft: theme.spacing(7.5),
            marginBottom: theme.spacing(2)
        },
        circleInProgress: {
            width: CIRCLE_SIZE,
            height: CIRCLE_SIZE,
            backgroundColor: "#AAEDF9",
            borderRadius: 100,
            marginLeft: 9,
            marginRight: 36
        },
        circleDone: {
            width: CIRCLE_SIZE,
            height: CIRCLE_SIZE,
            backgroundColor: "#B0EED3",
            borderRadius: 100,
            marginLeft: 9,
            marginRight: 36
        },
        circlePending: {
            width: CIRCLE_SIZE,
            height: CIRCLE_SIZE,
            backgroundColor: theme.palette.warning.main,
            borderRadius: 100,
            marginLeft: 9,
            marginRight: 36
        }
    });

export default salesProgressStyles;
