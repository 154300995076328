import { Controller } from "react-hook-form";
import FilledInput, { FilledInputProps } from "@material-ui/core/FilledInput";
import Typography from "@material-ui/core/Typography";
import React, { FC, Fragment, useState } from "react";
import { useFilledInputStyles } from "./styles";

interface ControlledTimePickerProps extends FilledInputProps {
    control: any;
    errors: boolean;
    name: string;
    required?: boolean;
    label?: string;
    min?: any;
}

export const ControlledTimePicker: FC<ControlledTimePickerProps> = ({ control, errors, name, placeholder, label, required, ...props }) => {
    const classes = useFilledInputStyles();
    const [timeFocused, setTimeFocused] = useState(false);

    return (
        <Fragment>
            {label && <Typography style={{ fontWeight: 600, fontSize: "14px" }}>{label}</Typography>}
            <Controller
                rules={{
                    validate: props.min
                        ? time => {
                              const firstTime = time.valueOf();
                              const secondTime = props.min.valueOf();
                              return secondTime <= firstTime || `falsche Zeit`;
                          }
                        : undefined
                }}
                control={control}
                name={name ?? "time"}
                render={({ field }) => (
                    <FilledInput
                        {...field}
                        placeholder={placeholder}
                        className={classes.filledInput}
                        required
                        type={timeFocused ? "time" : "text"}
                        error={!!errors}
                        onFocus={() => setTimeFocused(true)}
                        onBlur={() => setTimeFocused(false)}
                        inputProps={{ min: props.min }}
                    />
                )}
            />
        </Fragment>
    );
};
