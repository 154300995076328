export const BooksIcon = () => (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.21875 10.1086V1.17551C4.21875 1.04551 4.11375 0.94043 3.98437 0.94043H0.703125C0.57375 0.94043 0.46875 1.04551 0.46875 1.17551V10.1086H4.21875ZM2.10937 3.76142C2.10937 3.63142 2.21437 3.52634 2.34375 3.52634C2.47312 3.52634 2.57812 3.63142 2.57812 3.76142V6.58241C2.57812 6.71241 2.47312 6.81749 2.34375 6.81749C2.21437 6.81749 2.10937 6.71241 2.10937 6.58241V3.76142Z"
            fill="#393F49"
        />
        <path
            d="M0.46875 10.5791V14.3404C0.46875 14.4704 0.57375 14.5755 0.703125 14.5755H3.98437C4.11375 14.5755 4.21875 14.4704 4.21875 14.3404V10.5791H0.46875Z"
            fill="#393F49"
        />
        <path
            d="M8.67187 10.1086V1.17551C8.67187 1.04551 8.56687 0.94043 8.4375 0.94043H5.15625C5.02687 0.94043 4.92188 1.04551 4.92188 1.17551V10.1086H8.67187ZM6.5625 3.76142C6.5625 3.63142 6.6675 3.52634 6.79687 3.52634C6.92625 3.52634 7.03125 3.63142 7.03125 3.76142V6.58241C7.03125 6.71241 6.92625 6.81749 6.79687 6.81749C6.6675 6.81749 6.5625 6.71241 6.5625 6.58241V3.76142Z"
            fill="#393F49"
        />
        <path
            d="M4.92188 10.5791V14.3404C4.92188 14.4704 5.02687 14.5755 5.15625 14.5755H8.4375C8.56687 14.5755 8.67187 14.4704 8.67187 14.3404V10.5791H4.92188Z"
            fill="#393F49"
        />
        <path
            d="M13.58 9.51612L11.8894 0.745436C11.8777 0.68408 11.8421 0.630246 11.7905 0.595218C11.7389 0.560191 11.6754 0.546791 11.6147 0.559251L8.393 1.1841C8.26597 1.20855 8.183 1.33197 8.20737 1.45938L9.89815 10.2303L13.58 9.51612ZM10.4932 3.41056C10.6196 3.38471 10.7431 3.4691 10.7679 3.59675L11.3018 6.36649C11.3262 6.49391 11.2432 6.61709 11.1162 6.64177C11.1012 6.64483 11.0862 6.64624 11.0714 6.64624C10.9613 6.64624 10.8633 6.56819 10.8415 6.45559L10.3076 3.68585C10.2832 3.55843 10.3662 3.43501 10.4932 3.41056Z"
            fill="#393F49"
        />
        <path
            d="M9.98633 10.6917L10.6981 14.3846C10.7199 14.497 10.8181 14.575 10.928 14.575C10.9428 14.575 10.9576 14.5736 10.9728 14.5706L14.1945 13.946C14.2555 13.9342 14.3094 13.8985 14.3443 13.8468C14.3792 13.795 14.3921 13.7318 14.3802 13.6707L13.6684 9.97754L9.98633 10.6917Z"
            fill="#393F49"
        />
    </svg>
);
