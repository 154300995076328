import { Controller } from "react-hook-form";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import React, { CSSProperties, FC, Fragment, ReactElement } from "react";

interface ControlledCheckboxProps extends CheckboxProps {
    control: any;
    errors?: boolean;
    name: string;
    required?: boolean;
    label?: string | ReactElement;
    style?: CSSProperties;
}

export const ControlledCheckbox: FC<ControlledCheckboxProps> = ({ control, errors, name, label, required, style, ...props }) => {
    return (
        <Fragment>
            <Controller
                control={control}
                name={name}
                render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                        control={<Checkbox {...props} color="primary" style={style} checked={value} onChange={onChange} />}
                        label={<Typography style={{ fontSize: 15 }}>{label}</Typography>}
                    />
                )}
            />
        </Fragment>
    );
};
