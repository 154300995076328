import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useRouter } from "next/router";
import { FC, useState, useEffect, Fragment } from "react";
import { setProgressColor } from "../helpers-progress";
import { AccessKeyIcon, BadgeIcon, BooksIcon, CircleGroupIcon, GlassesIcon } from "../icons";
import salesProgressStyles from "../styles";
import TimelineItem from "../timeline-item";
import TitleIconComponent from "../title-icon-component";

const useStyles = makeStyles(salesProgressStyles);

interface TimelineListProps {
    isButtons?: boolean;
    progressType: any;
    onUpdateStatus: any;
    realEstate: any;
    onBuyerEmail?: (realEstateId: string) => void;
    handleDocumentUpload?: any;
}

export const TimelineList: FC<TimelineListProps> = ({ isButtons, progressType, onUpdateStatus, realEstate, onBuyerEmail, handleDocumentUpload }) => {
    const classes = useStyles({ isButtons });
    const router = useRouter();

    const handleCompleteExpose = () => {
        if (progressType.Expose_in_preparation === "in-progress") {
            onUpdateStatus("EXPOSE_CREATE");
        }
    };

    const handleSendInterestedBuyerEmail = () => {
        if (onBuyerEmail) onBuyerEmail(realEstate._id);
    };

    const handleClickInterestFoundBtn = async () => {
        onUpdateStatus("NOTARY_APPOINTMENT_TO_AGREE", { expose: { status: "present" } });
        // handleSendInterestedBuyerEmail();
    };

    const handleClickDealDoneBtn = async () => {
        onUpdateStatus("EXPOSE_DATA_OBTAIN", { oneClickDeal: { dealConfirmed: true, dealDoneDocument: { clicked: true } } });
    };

    return (
        <Fragment>
            <div>
                <TitleIconComponent title="Immobilie bewerten" progress={progressType.Valuate_property}>
                    <CircleGroupIcon />
                </TitleIconComponent>
                <ul className={classes.sectionList}>
                    <TimelineItem text="Bewertungstermin" progress={progressType.Evaluation_date} />
                    <TimelineItem text="Maklerauftrag erteilen" isButtons progress={progressType.Place_brokerage_order}>
                        {isButtons && progressType.Place_brokerage_order !== "done" && (
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                className={classes.buttonBlue}
                                onClick={() => router.push("/deal")}
                                title={progressType.Expose_in_preparation !== "in-progress" ? "Please, confirm the deal" : ""}
                            >
                                Zum Maklerauftrag
                            </Button>
                        )}

                        {!isButtons && progressType.Place_brokerage_order !== "done" && (
                            <Button color={"primary"} variant={"contained"} className={classes.buttonBlue} onClick={handleClickDealDoneBtn}>
                                Maklerauftrag bereits erteilt
                            </Button>
                        )}

                        {!isButtons &&
                            progressType.Place_brokerage_order === "done" &&
                            realEstate?.oneClickDeal?.dealDoneDocument?.clicked &&
                            !realEstate?.oneClickDeal?.dealDoneDocument?.file?.src && (
                                <Button variant={"contained"} color={"primary"} className={classes.buttonPrimary} onClick={handleDocumentUpload}>
                                    Unterschriebenen Maklerauftrag hochladen
                                </Button>
                            )}
                    </TimelineItem>
                </ul>
            </div>
            <div>
                <TitleIconComponent title="Vorbereitung Ihres Immobilienexposés" progress={progressType.Preparation_RE_expose}>
                    <BooksIcon />
                </TitleIconComponent>
                <ul className={classes.sectionList}>
                    <TimelineItem text="Fehlende Grunddaten ergänzen" progress={progressType.Complete_basic_data} />
                    <TimelineItem text="Fehlende Kontakte ergänzen" progress={progressType.Add_missing_contacts} />
                    <TimelineItem text="Vollmacht erteilen" progress={progressType.Grant_warrant} />
                    {/* <TimelineItem text="Fototermin buchen" isButtons progress={progressType.Book_photo_session}>
                        {isButtons && progressType.Book_photo_session !== "done" && (
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                className={classes.buttonPrimary}
                                disabled={progressType.Book_photo_session !== "in-progress"}
                            >
                                Fototermin jetzt bei onpreo buchen
                            </Button>
                        )}
                    </TimelineItem> */}
                    <TimelineItem text="Energieausweis hochladen" progress={progressType.Upload_energy_certificate} />
                    <TimelineItem text="Exposé in Vorbereitung" isButtons progress={progressType.Expose_in_preparation}>
                        {isButtons && progressType.Expose_in_preparation !== "done" && (
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                className={classes.buttonPrimary}
                                onClick={handleCompleteExpose}
                                disabled={progressType.Expose_in_preparation !== "in-progress"}
                            >
                                Exposé abschließen
                            </Button>
                        )}
                    </TimelineItem>
                </ul>
            </div>
            <div>
                <TitleIconComponent title="Besichtigungstermine und Käuferauswahl" progress={progressType.Viewing_appointments_and_buyer_selection}>
                    <GlassesIcon />
                </TitleIconComponent>

                {!isButtons && progressType.Viewing_appointments_and_buyer_selection !== "done" && (
                    <div className={classes.sectionItem}>
                        <div
                            className={classes.dashedBorder}
                            style={{
                                borderLeft: `2px dashed ${setProgressColor(progressType.Viewing_appointments_and_buyer_selection)}`,
                                height: "100%",
                                top: 0
                            }}
                        />

                        <Grid container className={classes.interestedBuyerButton}>
                            <Button
                                disabled={progressType.Expose_in_preparation !== "done"}
                                variant="contained"
                                color="primary"
                                onClick={handleClickInterestFoundBtn}
                            >
                                Interessent gefunden
                            </Button>
                        </Grid>
                    </div>
                )}
                <ul className={classes.sectionList}>
                    <TimelineItem text="Besichtigungen werden vereinbart" progress={progressType.Viewings_are_arranged} />
                    <TimelineItem text="Käufer auswählen" progress={progressType.Select_buyers} />
                </ul>
            </div>
            <div>
                <TitleIconComponent title="Notartermin" progress={progressType.Notary_appointment}>
                    <BadgeIcon />
                </TitleIconComponent>
                <ul className={classes.sectionList}>
                    <TimelineItem text="Kaufvertragsentwurf wird erstellt" progress={progressType.Draft_purchase_agreement_is_created} />
                    <TimelineItem text="Notartermin wird vereinbart" progress={progressType.Notary_appointment_is_agreed} />
                    <TimelineItem text="Kaufvertrag unterschreiben" progress={progressType.Sign_purchase_agreement} />
                </ul>
            </div>
            <div>
                <TitleIconComponent title="Schlüsselübergabe" progress={progressType.Get_keys}>
                    <AccessKeyIcon />
                </TitleIconComponent>
                <div className={classes.lastItem}>
                    <div className={classes.circle} style={{ backgroundColor: setProgressColor(progressType.Key_is_handed_over_to_owner) }} />
                    <Typography className={classes.itemName}>Schlüssel wird an Eigentümer übergeben</Typography>
                </div>
            </div>
        </Fragment>
    );
};
