export const GlassesIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.5625 10.9462V4.0625C1.5625 3.02688 2.40187 2.1875 3.4375 2.1875H4.0625C4.235 2.1875 4.375 2.0475 4.375 1.875V1.25C4.375 1.0775 4.235 0.9375 4.0625 0.9375H3.4375C1.71156 0.9375 0.3125 2.33656 0.3125 4.0625V12.3588C0.63 11.8072 1.05656 11.3278 1.5625 10.9462Z"
            fill="#393F49"
        />
        <path
            d="M15.9375 2.1875H16.5625C17.5981 2.1875 18.4375 3.02688 18.4375 4.0625V10.9462C18.9434 11.3275 19.37 11.8072 19.6875 12.3588V4.0625C19.6875 2.33656 18.2884 0.9375 16.5625 0.9375H15.9375C15.765 0.9375 15.625 1.0775 15.625 1.25V1.875C15.625 2.0475 15.765 2.1875 15.9375 2.1875Z"
            fill="#393F49"
        />
        <path
            d="M15.625 10.625C14.2309 10.625 12.9991 11.3316 12.2669 12.4047C11.7547 11.7062 10.9306 11.25 10 11.25C9.06937 11.25 8.24531 11.7062 7.73312 12.4047C7.00094 11.3316 5.76906 10.625 4.375 10.625C2.135 10.625 0.3125 12.4475 0.3125 14.6875C0.3125 16.9275 2.135 18.75 4.375 18.75C6.615 18.75 8.4375 16.9275 8.4375 14.6875V14.0625C8.4375 13.2009 9.13844 12.5 10 12.5C10.8616 12.5 11.5625 13.2009 11.5625 14.0625V14.6875C11.5625 16.9275 13.385 18.75 15.625 18.75C17.865 18.75 19.6875 16.9275 19.6875 14.6875C19.6875 12.4475 17.865 10.625 15.625 10.625Z"
            fill="#393F49"
        />
    </svg>
);
