import { FC, Fragment } from "react";
import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme =>
    createStyles({
        background: {
            height: ({ slim, imageOrColor, type, fullBackground }: PageFormatProps) => (fullBackground ? "100vh" : "600px"),
            // background: theme.palette.primary.main,
            background: ({ slim, imageOrColor, type, fullBackground }: PageFormatProps) => {
                if (type) {
                    return type === "color"
                        ? imageOrColor
                        : type === "image"
                        ? `url('${imageOrColor ?? "/imgs/bgn.jpg"} ') ${theme.palette.primary.main + "c0"} no-repeat`
                        : `url("/imgs/bgn.jpg") ${theme.palette.primary.main + "c0"} no-repeat`;
                } else return `url('/imgs/bgn.jpg') ${theme.palette.primary.main + "c0"} no-repeat`;
            },
            backgroundSize: "cover !important",
            backgroundColor: ({ slim, imageOrColor, type, fullBackground }: PageFormatProps) => {
                if (type) {
                    return type === "color" ? imageOrColor : theme.palette.primary.main + "e0";
                } else return theme.palette.primary.main + "e0";
            },
            backgroundBlendMode: "multiply",
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            zIndex: -1
        },
        container: {
            paddingTop: 76 + theme.spacing(4), // APP_BAR_HEIGHT + spacing
            paddingLeft: ({ slim, imageOrColor, type, fullBackground }: PageFormatProps) => theme.spacing(slim ? 6 : 6),
            paddingRight: ({ slim, imageOrColor, type, fullBackground }: PageFormatProps) => theme.spacing(slim ? 6 : 6),
            display: ({ slim, imageOrColor, type, fullBackground }: PageFormatProps) => (slim ? "flex" : undefined),
            justifyContent: ({ slim, imageOrColor, type, fullBackground }: PageFormatProps) => (slim ? "center" : undefined),
            [theme.breakpoints.down("lg")]: {
                paddingTop: theme.spacing(6)
            }
        }
    })
);

interface PageFormatProps {
    slim?: boolean;
    sidePadding?: boolean;
    fullWidth?: boolean;
    imageOrColor?: string;
    type?: string;
    fullBackground?: boolean;
}

export const PageFormat: FC<PageFormatProps> = ({ slim, fullWidth, imageOrColor, type, children, fullBackground }) => {
    const classes = useStyles({ slim, imageOrColor, type, fullBackground });

    return (
        <Fragment>
            <div className={classes.background} />
            <div id={"page-formatter"} className={classes.container}>
                <div style={{ maxWidth: slim ? "min(1200px, 100%)" : "100%", width: fullWidth ? "100%" : undefined }}>{children}</div>
            </div>
        </Fragment>
    );
};
