import { type ImageDetails } from "./commons";

// Sprengnetter related
// https://app.swaggerhub.com/apis-docs/SprengnetterAVM/avm/1.0.0#/Bewertungsabfragen/valuation (DEPRECATED)
// https://avm.sprengnetter.de/service/api/help/index.html
export enum RealEstateCategory {
    ETW = "ETW",
    EFH = "EFH",
    MFH = "MFH",
    GRD = "GRD"
}

export enum RealEstateConstruction {
    // For EFH and MFH
    DOPPELHAUS = "DOPPELHAUS",
    FREISTEHEND = "FREISTEHEND",
    REIHEN_ENDHAUS = "REIHEN_ENDHAUS",
    REIHEN_MITTELHAUS = "REIHEN_MITTELHAUS",
    BUNGALOW = "BUNGALOW",
    // EINFAMILIENHAUS = "EINFAMILIENHAUS", DEPRECATED check docs at the top
    // new
    VILLA = "VILLA",
    FARMHOUSE = "FARMHOUSE",

    // For onOffice EFH/MFH
    REIHENHAUS = "REIHENHAUS",
    REIHENECK = "REIHENECK",
    EINFAMILIENHAUS = "EINFAMILIENHAUS",
    STADTHAUS = "STADTHAUS",
    RESTHOF = "RESTHOF",
    LANDHAUS = "LANDHAUS",
    SCHLOSS = "SCHLOSS",
    ZWEIFAMILIENHAUS = "ZWEIFAMILIENHAUS",
    MEHRFAMILIENHAUS = "MEHRFAMILIENHAUS",
    BERGHÜTTE = "BERGHÜTTE",
    CHALET = "CHALET",
    STRANDHAUS = "STRANDHAUS",
    GESCHÄFTSHAUS = "GESCHÄFTSHAUS",
    WOHNANLAGE = "WOHNANLAGE",
    BESONDERE_IMMOBILIE = "BESONDERE_IMMOBILIE",

    // For ETW only
    LOFT = "LOFT",
    PENTHOUSE = "PENTHOUSE",
    SOUTERRAIN = "SOUTERRAIN",
    ERDGESCHOSSWOHNUNG = "ERDGESCHOSSWOHNUNG",
    ETAGENWOHNUNG = "ETAGENWOHNUNG",
    MAISONETTE = "MAISONETTE",
    TERRASSENWOHNUNG = "TERRASSENWOHNUNG",
    DACHGESCHOSS = "DACHGESCHOSS",
    // Only ETW in Germany
    HOCHPARTERRE = "HOCHPARTERRE",
    // Only ETW in Austria
    DACHGESCHOSSPENTHOUSE = "DACHGESCHOSSPENTHOUSE",
    MITGARTENTEIL = "MITGARTENTEIL",
    ROHDACHBODEN = "ROHDACHBODEN", // for onOffice

    // For GRD (as per OpenImmo)
    WOHNEN = "WOHNEN",
    GEWERBE = "GEWERBE",
    INDUSTRIE = "INDUSTRIE",
    LAND_FORSTWIRSCHAFT = "LAND_FORSTWIRSCHAFT",
    FREIZEIT = "FREIZEIT",
    GEMISCHT = "GEMISCHT",
    GEWERBEPARK = "GEWERBEPARK",
    SONDERNUTZUNG = "SONDERNUTZUNG",
    SEELIEGENSCHAFT = "SEELIEGENSCHAFT"
}

export enum EquipmentValue {
    EINFACH = "EINFACH",
    EINFACH_MITTEL = "EINFACH_MITTEL",
    MITTEL = "MITTEL",
    MITTEL_GEHOBEN = "MITTEL_GEHOBEN",
    GEHOBEN = "GEHOBEN",
    GEHOBEN_STARK_GEHOBEN = "GEHOBEN_STARK_GEHOBEN",
    STARK_GEHOBEN = "STARK_GEHOBEN"
}

export enum EquipmentFurnishing {
    VOLL = "VOLL",
    TEIL = "TEIL",
    NICHT = "NICHT"
}

export enum EquipmentInsulatedExteriorWalls {
    AUSSENWAENDE_GEDAEMMT = "AUSSENWAENDE_GEDAEMMT",
    AUSSENWAENDE_NICHT_GEDAEMMT = "AUSSENWAENDE_NICHT_GEDAEMMT"
}

export enum EquipmentStoreRoom {
    AUSSERHALB = "AUSSERHALB",
    INNERHALB = "INNERHALB",
    INNERHALB_UND_AUSSERHALB = "INNERHALB_UND_AUSSERHALB",
    KEINER = "KEINER"
}

export enum EquipmentBathRoom {
    INNENLIEGEND = "INNENLIEGEND",
    MEHR_ALS_EIN_BAD = "MEHR_ALS_EIN_BAD",
    MIT_FENSTER = "MIT_FENSTER",
    EIN_BAD = "EIN_BAD"
}

export enum EquipmentRoofCovering {
    DACHPAPPE = "DACHPAPPE",
    DACHPFANNEN = "DACHPFANNEN",
    METALL = "METALL",
    SCHIEFER = "SCHIEFER",
    SONSTIGE = "SONSTIGE"
}

export enum EquipmentWindows {
    ISOLIERVERGLASUNG = "ISOLIERVERGLASUNG",
    ZWEIFACHVERGLASUNG = "ZWEIFACHVERGLASUNG",
    DREIFACHVERGLASUNG = "DREIFACHVERGLASUNG",
    SPEZIALVERGLASUNG = "SPEZIALVERGLASUNG",
    RAUMHOHE_VERGLASUNG = "RAUMHOHE_VERGLASUNG",
    KASTENFENSTER = "KASTENFENSTER",
    EINFACH = "EINFACH",
    SONSTIGE = "SONSTIGE"
}

export enum EquipmentFloor {
    FLIESEN = "FLIESEN",
    KUNSTSTOFF_PVC = "KUNSTSTOFF_PVC",
    PARKETT_NATURSTEIN = "PARKETT_NATURSTEIN",
    TEPPICH_LAMINAT = "TEPPICH_LAMINAT",
    SONSTIGE = "SONSTIGE",
    KORK = "KORK",
    SICHTBETON = "SICHTBETON",
    LAMINAT = "LAMINAT",
    VINYL = "VINYL",
    NATURSTEIN = "NATURSTEIN"
}

export enum EquipmentGuestToilet {
    GAESTE_WC = "GAESTE_WC",
    KEIN_GAESTE_WC = "KEIN_GAESTE_WC"
}

export enum EquipmentHeating {
    FUSSBODENHEIZUNG = "FUSSBODENHEIZUNG",
    EINZELOEFEN = "EINZELOEFEN",
    ZENTRALHEIZUNG = "ZENTRALHEIZUNG",
    SONSTIGE = "SONSTIGE",
    ETAGENHEIZUNG = "ETAGENHEIZUNG"
}

export enum EquipmentSurfaceMountedInstallation {
    LEITUNGEN_AUF_PUTZ = "LEITUNGEN_AUF_PUTZ",
    LEITUNGEN_NICHT_AUF_PUTZ = "LEITUNGEN_NICHT_AUF_PUTZ"
}

export enum ResidentialArea {
    WINTERGARTEN = "WINTERGARTEN",
    BALKON_UEBER_10 = "BALKON_UEBER_10",
    BALKON_UNTER_10 = "BALKON_UNTER_10",
    KEINBALKON = "KEINBALKON"
}

export enum ResidentialREArea {
    SEHR_GUT = "sehr gut",
    GUT = "gut",
    MEDIUM = "medium",
    SCHLECHT = "schlecht",
    SEHR_SCHLECHT = "sehr schlecht"
}

// ImmoScout Energy Certificate ---------------------------------
// Based on https://api.immobilienscout24.de/api-docs/energy-certificates/
//

//easier to map enum values in the select dropdown menu rather than writing all values every single time
export enum EfficiencyClassValue {
    "Nicht Anwendbar" = "NOT_APPLICABLE",
    "A+" = "A+",
    "A" = "A",
    "B" = "B",
    "C" = "C",
    "D" = "D",
    "E" = "E",
    "F" = "F",
    "G" = "G",
    "H" = "H"
}

type EfficiencyClass = "NOT_APPLICABLE" | "A+" | "A" | "B" | "C" | "D" | "E" | "F" | "G" | "H";

//easier to map enum values in the select dropdown menu rather than writing all values every single time
export enum EnergySourcesEnev2014Values {
    "keine Angabe" = "NO_INFORMATION",
    "Erdwärme" = "GOETHERMAL",
    "Solar" = "SOLAR_HEATING",
    "Holzpellets" = "PELLET_HEATING",
    "Gas" = "GAS",
    "Öl" = "OIL",
    "Fernwärme" = "DISTRICT_HEATING",
    "Strom" = "ELECTRICITY",
    "Kohle" = "COAL",
    "Erdgas leicht" = "ACID_GAS",
    "Erdgas schwer" = "SOUR_GAS",
    "Flüssiggas" = "LIQUID_GAS",
    "Fernwärme Dampf" = "STEAM_DISTRICT_HEATING",
    "Holz" = "WOOD",
    "Holz-Hackschnitzel" = "WOOD_CHIPS",
    "Kohle/Koks" = "COAL_COKE",
    "Nahwärme" = "LOCAL_HEATING",
    "Wärmelieferung" = "HEAT_SUPPLY",
    "Bioenergie" = "BIO_ENERGY",
    "Windenergie" = "WIND_ENERGY",
    "Wasserenergie" = "HYDRO_ENERGY",
    "Umweltwärme" = "ENVIRONMENTAL_THERMAL_ENERGY",
    "KWK fossil" = "COMBINED_HEAT_AND_POWER_FOSSIL_FUELS",
    "KWK erneuerbar" = "COMBINED_HEAT_AND_POWER_RENEWABLE_ENERGY",
    "KWK regenerativ" = "COMBINED_HEAT_AND_POWER_REGENERATIVE_ENERGY",
    "KWK Bio" = "COMBINED_HEAT_AND_POWER_BIO_ENERGY"
}

export type EnergySourcesEnev2014 =
    | "NO_INFORMATION" // (keine Angabe)
    | "GEOTHERMAL" // (Erdwärme)
    | "SOLAR_HEATING" // (Solar)
    | "PELLET_HEATING" // (Holzpellets)
    | "GAS" // (Gas)
    | "OIL" // (Öl)
    | "DISTRICT_HEATING" // (Fernwärme)
    | "ELECTRICITY" // (Strom)
    | "COAL" // (Kohle)
    | "ACID_GAS" // (Erdgas leicht)
    | "SOUR_GAS" // (Erdgas schwer)
    | "LIQUID_GAS" // (Flüssiggas)
    | "STEAM_DISTRICT_HEATING" // (Fernwärme Dampf)
    | "WOOD" // (Holz)
    | "WOOD_CHIPS" // (Holz-Hackschnitzel)
    | "COAL_COKE" // (Kohle/Koks)
    | "LOCAL_HEATING" // (Nahwärme)
    | "HEAT_SUPPLY" // (Wärmelieferung)
    | "BIO_ENERGY" // (Bioenergie)
    | "WIND_ENERGY" // (Windenergie)
    | "HYDRO_ENERGY" // (Wasserenergie)
    | "ENVIRONMENTAL_THERMAL_ENERGY" // (Umweltwärme)
    | "COMBINED_HEAT_AND_POWER_FOSSIL_FUELS" // (KWK fossil)
    | "COMBINED_HEAT_AND_POWER_RENEWABLE_ENERGY" // (KWK erneuerbar)
    | "COMBINED_HEAT_AND_POWER_REGENERATIVE_ENERGY" // (KWK regenerativ)
    | "COMBINED_HEAT_AND_POWER_BIO_ENERGY"; // (KWK Bio)

type CertificatePre2014RequiredNonC = {
    buildingType: "NON_COMMERCIAL";
    creationDate: "BEFORE_01_MAY_2014";
    certificateType: "ENERGY_REQUIRED";
    thermalCharacteristic: number;
    energySourceEnev2014: EnergySourcesEnev2014;
};
type CertificatePre2014RequiredC = {
    buildingType: "COMMERCIAL";
    creationDate: "BEFORE_01_MAY_2014";
    certificateType: "ENERGY_REQUIRED";
    thermalCharacteristic: number;
    energySourceEnev2014: EnergySourcesEnev2014;
};
type CertificatePost2014RequiredNonC = {
    buildingType: "NON_COMMERCIAL";
    creationDate: "FROM_01_MAY_2014";
    certificateType: "ENERGY_REQUIRED";
    thermalCharacteristic: number;
    energySourceEnev2014: EnergySourcesEnev2014;
    energyEfficiencyClass: EfficiencyClass;
};
type CertificatePost2014RequiredC = {
    buildingType: "COMMERCIAL";
    creationDate: "FROM_01_MAY_2014";
    certificateType: "ENERGY_REQUIRED";
    thermalCharacteristicElectricity: number;
    thermalCharacteristicHeating: number;
    energySourceEnev2014: EnergySourcesEnev2014;
};
type CertificatePre2014ConsumptionNonC = {
    buildingType: "NON_COMMERCIAL";
    creationDate: "BEFORE_01_MAY_2014";
    certificateType: "ENERGY_CONSUMPTION";
    thermalCharacteristic: number;
    energyConsumptionContainsWarmWater: "YES" | "NO" | undefined;
    energySourceEnev2014: EnergySourcesEnev2014;
};
type CertificatePre2014ConsumptionC = {
    buildingType: "COMMERCIAL";
    creationDate: "BEFORE_01_MAY_2014";
    certificateType: "ENERGY_CONSUMPTION";
    electricityConsumption: number;
    heatingConsumption: number;
    energySourceEnev2014: EnergySourcesEnev2014;
};
type CertificatePost2014ConsumptionNonC = {
    buildingType: "NON_COMMERCIAL";
    creationDate: "FROM_01_MAY_2014";
    certificateType: "ENERGY_CONSUMPTION";
    thermalCharacteristic: number;
    energySourceEnev2014: EnergySourcesEnev2014;
    energyEfficiencyClass: EfficiencyClass;
};
type CertificatePost2014ConsumptionC = {
    buildingType: "COMMERCIAL";
    creationDate: "FROM_01_MAY_2014";
    certificateType: "ENERGY_CONSUMPTION";
    energyConsumptionElectricity: number;
    energyConsumptionHeating: number;
};

export type AllEnergyCertificateKeys =
    | keyof CertificatePre2014RequiredNonC
    | keyof CertificatePre2014RequiredC
    | keyof CertificatePost2014RequiredNonC
    | keyof CertificatePost2014RequiredC
    | keyof CertificatePre2014ConsumptionNonC
    | keyof CertificatePre2014ConsumptionC
    | keyof CertificatePost2014ConsumptionNonC
    | keyof CertificatePost2014ConsumptionC;
export type EnergyCertificate = (
    | CertificatePre2014RequiredNonC
    | CertificatePre2014RequiredC
    | CertificatePost2014RequiredNonC
    | CertificatePost2014RequiredC
    | CertificatePre2014ConsumptionNonC
    | CertificatePre2014ConsumptionC
    | CertificatePost2014ConsumptionNonC
    | CertificatePost2014ConsumptionC
) & { img: ImageDetails };

// Sprengnetter unrelated ---------------------------------------

export enum RealEstateCondition {
    RENOVATED = "RENOVATED",
    MAINTAINED = "MAINTAINED",
    REFURBISHED = "REFURBISHED",
    NEW = "NEW",
    TO_BE_RENOVATED = "TO_BE_RENOVATED",
    NEED_TO_BE_RENOVATED = "NEED_TO_BE_RENOVATED"
}

export enum RealEstateUse {
    VERMIETET = "VERMIETET",
    SELBST_BEWOHNT = "SELBST_BEWOHNT",
    LEERSTAND = "LEERSTAND",
    TEILWEISE_VERMIETET = "TEILWEISE_VERMIETET",
    KOMPLETT_VERMIETET = "KOMPLETT_VERMIETET"
}

export enum RealEstateStatus {
    AGREE_TO_START_PRICE = "AGREE_TO_START_PRICE", // Vereinbare den Startpreis
    EXPOSE_DATA_OBTAIN = "EXPOSE_DATA_OBTAIN", // Exposé-Daten einholen
    EXPOSE_APPROVAL_OBTAIN = "EXPOSE_APPROVAL_OBTAIN", // Eposé-Freigabe einholen
    EXPOSE_CREATE = "EXPOSE_CREATE", // Exposé erstellen
    PUT_RE_ON_PORTALS = "PUT_RE_ON_PORTALS", // Immobilie auf Portale stellen
    VIEWING_APPOINTMENTS = "VIEWING_APPOINTMENTS", // Besichtigungstermine
    FEEDBACK_TOUR_ROUND = "FEEDBACK_TOUR_ROUND", // Feedback Besichtigungsrunde
    CREATE_NEW_VISIT_ROUND = "CREATE_NEW_VISIT_ROUND", // Neue Besichtigungsrunde anlegen
    MARKETING_ANALYSIS = "MARKETING_ANALYSIS", // Vermarktungsanalyse
    BUYER_SELECTED = "BUYER_SELECTED", // Käufer ausgewählt
    RESERVED = "RESERVED", // Reserviert
    COMPLIANCE_CHECK = "COMPLIANCE_CHECK", // Compliance Check
    NOTARY_APPOINTMENT_TO_AGREE = "NOTARY_APPOINTMENT_TO_AGREE", // Notartermin zu vereinbaren
    NOTARY_APPOINTMENT_PENDING = "NOTARY_APPOINTMENT_PENDING", // Notartermin ausstehend
    POST_APPROVAL_DATE_TO_AGREE = "POST_APPROVAL_DATE_TO_AGREE", // Nachgenehmigungstermin zu vereinbaren
    POST_APPROVAL_DATE_PENDING = "POST_APPROVAL_DATE_PENDING", // Nachgenehmigungstermin ausstehend
    CUSTOMER_CANCEL = "CUSTOMER_CANCEL", // Kunde möchte kündigen
    HANDOVER_DATE_TO_AGREE = "HANDOVER_DATE_TO_AGREE", // Übergabetermin zu vereinbaren
    SOLD = "SOLD", // Verkauft
    LOST = "LOST" // Verloren
}

export enum RealEstateExtras {
    BALCONY = "BALCONY",
    TERRACE = "TERRACE",
    GARDEN = "GARDEN",
    CONSERVATORY = "CONSERVATORY",
    STORAGE_ROOM_INDOOR = "STORAGE_ROOM_INDOOR",
    STORAGE_ROOM_OUTDOOR = "STORAGE_ROOM_OUTDOOR",
    CELLAR = "CELLAR",
    POINT_OF_INTEREST = "POINT_OF_INTEREST",
    POOL = "POOL",
    SAUNA = "SAUNA",
    ELEVATOR = "ELEVATOR"
}

export enum RealEstateServices {
    CARETAKER = "CARETAKER",
    CLEANING = "CLEANING"
}

export enum RealEstateEnergySource {
    GAS = "GAS",
    ELECTRICITY = "ELECTRICITY",
    OIL = "OIL"
}

export type AdditionalFeatures = Record<string, number>;

export type CustomServices = Record<string, string>;

export type BrokerEntry = {
    brokerEntryArea: number;
    brokerEntryTypeRealEstate: number;
    brokerEntryHouseType1: number;
    brokerEntryHouseType2: number;
    brokerEntryRooms: number;
    brokerEntryYear: number;
    brokerEntryMaintenance: number;
    brokerEntryUse: number;
    brokerEntryBasement: number;
    brokerEntryParking: number;
    brokerEntryBalcony: number;
    brokerEntryTerasse: number;
    brokerEntryGarden: number;
    brokerEntryImpairment: number[];
    brokerEntryServices: string[];
    brokerEntryMarket: number;
    brokerEntryInvestor: number;
    brokerEntryApartment: number;
    brokerEntryFloors: number;
    brokerEntryRoof: number;
    brokerEntryFacade: number;
    brokerEntryHeating: number;
    brokerEntryRennovation: number;
    brokerEntryEquipment: number;
};

export type Rent = {
    factor: number;
    coldRentPerQm: number;
    targetRentPerQm: number;
    actual: {
        livingArea: number;
        commercialSpace: number;
    };
    possible: {
        livingArea: number;
        commercialSpace: number;
    };
    sprengnetter: {
        value: number;
        range: {
            min: number;
            max: number;
        };
    };
    yearlyColdRent?: number;
    houseRent?: number; // for ETW (hausgeld)
};

export type ExposeFields = {
    key: string;
    val: string | number | boolean;
};

export enum RealEstateProgress {
    AGREE_TO_START_PRICE = "AGREE_TO_START_PRICE", // Neue Immobilie
    CONTACTED_CUSTOMER = "CONTACTED_CUSTOMER", // Kontakt aufgenommen
    PRICE_ASSISTANT_DONE = "PRICE_ASSISTANT_DONE", // Einwertung vorgenommen
    ONE_CLICK_DEAL_CLOSED = "ONE_CLICK_DEAL_CLOSED", // Auftrag gewonnen
    LOST = "LOST", // Kein Interesse
    AD_PREPARATION = "AD_PREPARATION", // Inseratsvorbereitung
    INSPECTION = "INSPECTION", // Besichtigungsphase
    SALES = "SALES" // Verkaufsphase
}

export enum ProgressStep {
    // Akquise
    // Setting (Pipeline 1) ===============================================
    // Lead-Bearbeitung (Inbound)
    NEW_LEAD_INBOUND = "NEW_LEAD_INBOUND", // Neuer Lead
    INBOUND_CONTACT_ATTEMPT_1 = "INBOUND_CONTACT_ATTEMPT_1", // Kontaktversuch 1
    INBOUND_CONTACT_ATTEMPT_2 = "INBOUND_CONTACT_ATTEMPT_2", // Kontaktversuch 2
    INBOUND_CONTACT_ATTEMPT_3 = "INBOUND_CONTACT_ATTEMPT_3", // Kontaktversuch 3

    // Offline-Akquise (Outbound)
    NEW_LEAD_OUTBOUND = "NEW_LEAD_OUTBOUND", // Neuer Kontakt
    OUTBOUND_CONTACT_ATTEMPT_1 = "OUTBOUND_CONTACT_ATTEMPT_1", // Kontaktversuch 1
    OUTBOUND_CONTACT_ATTEMPT_2 = "OUTBOUND_CONTACT_ATTEMPT_2", // Kontaktversuch 2
    OUTBOUND_CONTACT_ATTEMPT_3 = "OUTBOUND_CONTACT_ATTEMPT_3", // Kontaktversuch 3

    // Closing (Pipeline 2) ===============================================
    // Potenzial Pipeline
    FAKE_LEADS = "FAKE_LEADS", // Fake-Leads
    NO_CONTACT = "NO_CONTACT", // Kein Kontakt (Nicht erreicht / falsche Tel)
    NOT_INTERESTED_IN_SELLING = "NOT_INTERESTED_IN_SELLING", // Kein Verkaufsinteresse (Immobilienbesitzer)
    COLD = "COLD", // Kalt (Verkauf in 24+ Monate)
    WARM = "WARM", // Warm (Verkauf in 6-24 Monaten)
    HOT = "HOT", // Heiss (Verkauf in < 6 Monaten)
    APPOINTMENT_ON_SITE_1 = "APPOINTMENT_ON_SITE_1", // Termin vor Ort 1
    APPOINTMENT_ON_SITE_2 = "APPOINTMENT_ON_SITE_2", // Termin vor Ort 2
    ONE_CLICK_DEAL_CLOSED = "ONE_CLICK_DEAL_CLOSED", // Auftrag gewonnen
    LOST = "LOST", // Auftrag verloren
    EXPORTED = "EXPORTED", // Immobilie Exportieren
    IMPORTED = "IMPORTED", // Importierte Immobilien

    // Verkauf (Pipeline 3) ===============================================
    DOCUMENTATION_PROCUREMENT = "DOCUMENTATION_PROCUREMENT", // Unterlagenbeschaffung
    EXPOSE_CREATION = "EXPOSE_CREATION", // Exposeerstellung
    MARKETING_START = "MARKETING_START", // Vermarktungsstart
    VIEWING_APPOINTMENTS = "VIEWING_APPOINTMENTS", // Besichtigungstermine
    BUYER_CHOICE = "BUYER_CHOICE", // Käuferauswahl
    NOTARY_APPOINTMENT = "NOTARY_APPOINTMENT", // Notartermin
    SOLD = "SOLD", // Verkauf

    // After Sales (Pipeline 4)(tbd.) ===============================================
    RECOMMENDATION_DOCUMENTS = "RECOMMENDATION_DOCUMENTS", // Empfehlungsunterlagen (Google-Bewertung / Video-Testimonial)
    INTRODUCE_REFERRAL_PROGRAM = "INTRODUCE_REFERRAL_PROGRAM", // Weiterempfehlungs-Programm vorstellen
    REAL_ESTATE_BIRTHDAY = "REAL_ESTATE_BIRTHDAY", // Immobiliengeburtstag

    // Partnerschafts-Pipeline ===============================================
    NEW_PARTNERSHIP_LEAD = "NEW_PARTNERSHIP_LEAD", // Neuer Partnerschafts-Lead
    NOT_TRANSFERABLE = "NOT_TRANSFERABLE", // Nicht vermittelbar
    PARTNER_CONTACTED = "PARTNER_CONTACTED", // Partner kontaktiert
    PARTNER_WON_MANDATE = "PARTNER_WON_MANDATE", // Partner hat Mandat gewonnen
    PARTNER_HAS_SOLD_PROPERTY = "PARTNER_HAS_SOLD_PROPERTY", // Partner hat Objekt verkauft
    KICKBACK_IS_PAID = "KICKBACK_IS_PAID", // Kick-Back ist bezahlt

    // VR-Banks-Partner-Pipeline ===============================================
    NEW_BANK_PARTNER_LEAD = "NEW_BANK_PARTNER_LEAD", // Neuer Partner-Lead
    BANK_PARTNER_CONTACTED = "BANK_PARTNER_CONTACTED", // Partner kontaktiert
    LEAD_CONSENT_PENDING = "LEAD_CONSENT_PENDING", // Freigabe ausstehend
    LEAD_SENT = "LEAD_SENT", // Lead gesendent
    // LEAD_ACCEPTED = "LEAD_ACCEPTED", // Lead angenommen
    LEAD_REJECTED = "LEAD_REJECTED", // Lead abgelehnt
    REAL_ESTATE_SOLD = "REAL_ESTATE_SOLD", // Immobilie verkauft

    // Marketing content pipeline
    NEW_LEAD_INBOUND_CONTENT = "NEW_LEAD_INBOUND_CONTENT",
    INBOUND_CONTACT_ATTEMPT_1_CONTENT = "INBOUND_CONTACT_ATTEMPT_1_CONTENT",
    INBOUND_CONTACT_ATTEMPT_2_CONTENT = "INBOUND_CONTACT_ATTEMPT_2_CONTENT",
    INBOUND_CONTACT_ATTEMPT_3_CONTENT = "INBOUND_CONTACT_ATTEMPT_3_CONTENT"
}

export type DaysOfWeek = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";
export type BookingSlots = {
    [key in DaysOfWeek]: { booked: boolean; timeSlot: [{ till: string; from: string }] };
};
