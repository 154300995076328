import React, { CSSProperties, FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";

// core components
import componentStyles from "./styles/index-navbar";
import { Grid } from "@material-ui/core";
import { LoadingButton } from "../../buttons";
import { useRouter } from "next/router";

const useStyles = componentStyles;

interface IndexNavbarProps {
    logo: {
        imgSrc: string;
        imgAlt: string;
    };
    style?: CSSProperties;
    showLogout?: boolean;
}

export const IndexNavbar: FC<IndexNavbarProps> = ({ logo, style, showLogout }) => {
    const classes = useStyles();
    const router = useRouter();

    const handleLogout = async () => {
        await router.push("/logout");
    };

    return (
        <>
            <AppBar position={"absolute"} elevation={0} classes={{ root: classes.appBarRoot }} style={{ ...style }}>
                <Toolbar className={classes.toolbar}>
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <img alt={logo.imgAlt} className={classes.headerImg} src={logo.imgSrc} />
                        </Box>
                    </Grid>
                    {showLogout && (
                        <Grid container justifyContent={"flex-end"} style={{ position: "absolute", right: 25 }}>
                            <LoadingButton variant="contained" color="primary" onClick={handleLogout}>
                                <DirectionsRunIcon style={{ height: 20, width: 20 }} /> Logout
                            </LoadingButton>
                        </Grid>
                    )}
                </Toolbar>
            </AppBar>
        </>
    );
};
