export const CircleGroupIcon = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.03125 7.17988V0C3.13336 0.124829 0 3.34217 0 7.28168C0 9.12449 0.686719 10.8084 1.81523 12.0929L7.03125 7.17988Z" fill="#393F49" />
        <path d="M7.5 7.04659H14.5254C14.4033 3.21522 11.3198 0.122478 7.5 0V7.04659Z" fill="#393F49" />
        <path
            d="M7.35805 7.5166L2.13477 12.4366C3.45008 13.7536 5.26437 14.5691 7.26523 14.5691C11.1929 14.5691 14.4005 11.4263 14.525 7.5166H7.35805Z"
            fill="#393F49"
        />
    </svg>
);
