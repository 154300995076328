import { createStyles, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import React, { CSSProperties, FC } from "react";

const useStyles = makeStyles(muiTheme =>
    createStyles({
        container: {
            position: ({ dark, isRelative }: { dark: boolean; isRelative: boolean }) => (isRelative ? "relative" : "absolute"),
            bottom: 0,
            width: "100vw",
            padding: muiTheme.spacing(2)
        },
        copyright: {
            fontSize: "12px",
            color: ({ dark, isRelative }: { dark: boolean; isRelative: boolean }) => (dark ? "#fff" : "#8898AA")
        },
        link: {
            fontSize: "12px",
            color: ({ dark, isRelative }: { dark: boolean; isRelative: boolean }) => (dark ? "#fff" : "#8898AA"),
            padding: muiTheme.spacing(0.5, 0)
        }
    })
);

interface StaticFooterProps {
    dark?: boolean;
    style?: CSSProperties;
    className?: any;
    isRelative?: boolean;
}

export const StaticFooter: FC<StaticFooterProps> = ({ dark = false, style, className, isRelative }) => {
    const classes = useStyles({ dark: false, isRelative });
    return (
        <div className={`${classes.container} ${className}`} style={style}>
            <Grid container item justifyContent={"space-between"}>
                <Grid container justifyContent={"center"} item xs={3}>
                    {/*<Typography className={classes.copyright}>*/}
                    {/*    © 2022*/}
                    {/*    <a style={{ fontWeight: 600, marginLeft: 6 }} href={"https://www.onpreo.com"}>*/}
                    {/*        onpreo AG*/}
                    {/*    </a>*/}
                    {/*</Typography>*/}
                </Grid>
                <Grid container justifyContent={"center"} alignItems={"center"} item xs={12} md={9} lg={9} xl={6}>
                    <Grid container justifyContent={"center"} item xs={4} md={3}>
                        <a target={"_blank"} href={"https://www.onpreo.com/terms-of-service"} className={classes.link}>
                            <Typography className={classes.link}>Impressum</Typography>
                        </a>
                    </Grid>
                    <Grid container justifyContent={"center"} item xs={4} md={3}>
                        <a target={"_blank"} href={"https://www.onpreo.com/agbs"} className={classes.link}>
                            <Typography className={classes.link}>AGB</Typography>
                        </a>
                    </Grid>
                    <Grid container justifyContent={"center"} item xs={4} md={3}>
                        <a target={"_blank"} href={"https://www.onpreo.com/privacy-policy"} className={classes.link}>
                            <Typography className={classes.link}>Datenschutz</Typography>
                        </a>
                    </Grid>
                    <Grid container justifyContent={"center"} item xs={12} md={3}>
                        <Typography className={classes.copyright}>
                            © {new Date().getFullYear()}
                            <a target={"_blank"} style={{ fontWeight: 600, marginLeft: 2 }} href={"https://www.onpreo.com"}>
                                onpreo AG
                            </a>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
