import { Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import boxShadows from "@onpreo/components/src/theme/box-shadow";

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: {
                flexGrow: 1
            },
            grid: {
                paddingTop: 15,
                display: "flex",
                width: "100%",
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { marginTop: -9 }
                }
                // marginLeft: 16,
                // marginRight: 16
            },
            grid2: {
                paddingTop: 60,
                display: "flex",
                width: "100%"
                // marginLeft: 15,
                // marginRight: "auto"
            },
            gridMarginLess: {
                paddingTop: 15,
                display: "flex",
                width: "100%",
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { marginBottom: 10 }
                }
                // marginRight: "auto"
            },
            button: {
                color: "#000",
                padding: 0,
                backgroundColor: "#FFFFFF",
                border: boxShadows.iconsPageButtonBoxShadow
            },
            iconButton: {
                display: "flex",
                background: "#FFFFFF",
                justifyContent: "center",
                padding: 0,
                width: 200,
                maxWidth: 200,
                backgroundColor: "#FFFFFF"
                // border: boxShadows.iconsPageButtonBoxShadow
            },
            buttonGrid: {
                paddingTop: 15,
                display: "flex",
                width: "100%"
                // marginRight: "auto"
            },
            buttonGrid1: {
                paddingTop: 24,
                display: "flex",
                width: "100%"
            },
            buttonGrid2: {
                paddingTop: 24,
                display: "flex",
                width: "100%",
                marginLeft: 15,
                marginRight: "auto"
            },
            bottomButtonsGrid: {
                paddingTop: 15,
                display: "flex",
                // marginLeft: 15,
                width: "100%"
                // marginRight: "auto"
            },
            container: {
                minHeight: 400,
                width: "100%",
                minWidth: 1200,
                "@media (max-width: 1024px)": {
                    width: 600,
                    minWidth: 500,
                    justifyContent: "center",
                    // maxHeight: 600,
                    "@media (orientation:landscape)": { width: 849, minWidth: 800 }
                }
            },
            containerInfoCards: {
                minHeight: 400,
                width: 1100
            },
            buttonCard: {
                display: "flex",
                background: "#FFFFFF",
                justifyContent: "center",
                padding: 10,
                minWidth: 200,
                maxWidth: 200,
                margin: 0,
                borderColor: "transparent",
                border: "2px solid",
                "&:hover": {
                    backgroundColor: "#FFFFFF",
                    borderColor: theme.palette.primary.main,
                    border: "2px solid"
                }
            },
            card: {
                height: 128,
                width: 190,
                marginBottom: 0
            },
            card1: {
                width: "63%",
                height: 150,
                background: theme.palette.background.paper,
                color: "#FFFFFF",
                justifyContent: "center",
                padding: 10
            },
            card2: {
                width: "63%",
                height: 150,
                // background: "linear-gradient(to right, #00bfff, #007FFF)",
                background: "#0071E3",
                color: "#FFFFFF",
                justifyContent: "center",
                padding: 10
            },
            card3: {
                width: "64%",
                maxWidth: 420,
                height: 144,
                background: "#0071E3",
                color: "#FFFFFF",
                justifyContent: "center",
                padding: theme.spacing(2, 2, 1, 2),
                margin: theme.spacing(1, 0),
                boxShadow: theme.shadows[8]
            },
            card4: {
                width: "80%",
                maxWidth: 600,
                height: 150,
                // background: "linear-gradient(to right, #00bfff, #007FFF)",
                background: "#0071E3",
                boxShadow: theme.shadows[8],
                color: "#FFFFFF",
                justifyContent: "center",
                padding: 10
            },
            filledInput: {
                borderRadius: "4px",
                width: 250,
                maxWidth: 250
            },
            filledInputErtragsrechner: {
                borderRadius: "4px",
                width: 310,
                maxWidth: 310
            },
            filledInputRenovation: {
                borderRadius: "4px",
                width: 180,
                maxWidth: 250
            },
            descriptionField: {
                paddingBottom: 5,
                paddingTop: 10,
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { fontSize: 14 }
                }
            },
            slider: {
                paddingTop: 70,
                display: "flex",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto"
            },
            menuItem: {
                display: "flex",
                justifyContent: "center",
                maxHeight: 260
            },
            cardMiddle: {
                background: "#FFFFFF",
                // background: "linear-gradient(to right, #00bfff, #007FFF)",
                // background: "linear-gradient(to right, #2DCE89, #2DCECC)",
                justifyContent: "center",
                padding: 0,
                marginBottom: 0,
                height: 244,
                border: "2px solid white",
                "&:hover": {
                    backgroundColor: "#FFFFFF",
                    borderColor: theme.palette.primary.main,
                    border: "2px solid"
                }
            },
            cardSide: {
                background: "#FFFFFF",
                justifyContent: "center",
                padding: 0,
                marginBottom: 0,
                height: 244,
                border: "2px solid transparent",
                "&:hover": {
                    backgroundColor: "#FFFFFF",
                    borderColor: theme.palette.primary.main,
                    border: "2px solid"
                }
            },
            buttonSide: {
                padding: 0,
                width: 270,
                height: 244,
                backgroundColor: "#FFFFFF",
                border: boxShadows.iconsPageButtonBoxShadow,
                pointerEvents: "none"
            },
            buttonMiddle: {
                padding: 0,
                width: 290,
                height: 244,
                zIndex: 1,
                backgroundColor: "#FFFFFF",
                border: boxShadows.iconsPageButtonBoxShadow,
                pointerEvents: "none"
            },
            iconButtonBig: {
                color: "#000",
                padding: 0,
                width: 250,
                height: 250,
                backgroundColor: "#FFFFFF",
                border: boxShadows.iconsPageButtonBoxShadow,
                "&:hover": {
                    backgroundColor: "#FFFFFF",
                    borderColor: theme.palette.primary.main,
                    border: "2px solid"
                }
            },
            cardButtonBig: {
                background: "#FFFFFF",
                justifyContent: "center",
                padding: 10,
                marginBottom: 0,
                height: 245
            },
            cardFields: {
                borderRadius: "4px",
                width: "100%"
            },
            cardBig1: {
                background: "#FFFFFF",
                justifyContent: "center",
                padding: 0,
                boxShadow: boxShadows.menuBoxShadow,
                height: 400,
                width: 375
            },
            cardBig2: {
                background: "#D2E3EE",
                justifyContent: "center",
                padding: 0,
                boxShadow: boxShadows.menuBoxShadow,
                height: 400,
                width: 375
            },
            backgroundCard: {
                minWidth: 1100,
                minHeight: 400,
                paddingTop: 30
            },
            inputGrid: {
                paddingTop: 10,
                display: "flex",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto"
            },
            menu: {
                minWidth: 300
            },
            tabsGrid: {
                width: "100%",
                justifyContent: "center",
                display: "flex"
            },
            tabs: {
                fontSize: 11,
                fontWeight: 600,
                boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
                borderRadius: "4px",
                color: "#212529",
                minWidth: "8%",
                marginLeft: theme.spacing(-1),
                border: "0.2px solid"
            },
            iPadGrid: {
                "@media (max-width: 1024px)": {
                    marginLeft: "-6%",
                    "@media (orientation:landscape)": { width: 1024 },
                    "@media (orientation:portrait)": { width: 768 }
                }
            },
            iPadMap: {
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { width: 700, maxWidth: 1024, marginLeft: "-13%" },
                    "@media (orientation:portrait)": { width: 400 }
                }
            },
            iPadGraph: {
                minHeight: 400,
                paddingTop: 30,
                paddingBottom: 20,
                minWidth: 1100,
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { width: 879, minWidth: 800 },
                    "@media (orientation:portrait)": { width: 768 }
                }
            },
            textNote: {
                fontSize: 14,
                lineHeight: 2,
                fontWeight: 600,
                color: "#000000",
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { fontSize: 12, lineHeight: 1.8 }
                }
            },
            textNoteGrid: {
                display: "grid",
                width: "27%",
                padding: 10,
                marginLeft: 0,
                marginRight: 0,
                border: "2px solid black",
                position: "absolute",
                right: 60,
                top: 290,
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { top: 185 }
                }
            },
            textNoteGridClosed: {
                display: "grid",
                width: "27%",
                padding: 10,
                marginLeft: 0,
                marginRight: 0,
                border: "2px solid black",
                position: "absolute",
                right: 60,
                top: 210,
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { top: 185 }
                }
            },
            iPadPadding: {
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { paddingTop: 14 }
                }
            },
            strategyCard: {
                width: 420,
                height: 150,
                color: "#FFFFFF",
                justifyContent: "center",
                padding: 10,
                maxWidth: 420,
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { marginBottom: 10 }
                }
            },
            iPadContainerGrid: {
                display: "flex",
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { marginTop: "-3%" }
                }
            },
            iPadItemGrid: {
                display: "flex",
                marginTop: "-4%",
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { marginTop: "-14%" }
                }
            },
            tableCell: {
                display: "flex",
                justifyContent: "center",
                verticalAlign: "center"
            },
            controlledFilledInput: {
                width: "100%",
                minHeight: "46px",
                margin: theme.spacing(1, 0, 1, 0),
                "@media (max-width: 1024px)": {
                    "@media (orientation:landscape)": { maxWidth: 350 }
                },
                "&:hover": {
                    background: "transparent"
                }
            },
            tooltip: {
                maxWidth: 250,
                marginBottom: -25
            },
            equipmentTooltip: {
                maxWidth: 500,
                whiteSpace: "break-spaces",
                textAlign: "left"
            },
            menuIconPriceFinder: {
                position: "fixed",
                // Zindex one less than the sidebar
                zIndex: 1199,
                //zIndex: 9999,
                //bottom: "8px",
                bottom: "20px",
                left: "20px",
                //backgroundColor: theme.palette.primary.main, //"rgb(211,211,211, .25)"
                backgroundColor: "transparent", //if you display it it will move when changing slide.
                //It gets shadowed by sidebar-Button. But necessary for saving form entries
                "&:hover": {
                    color: "#fff",
                    backgroundColor: theme.palette.primary.light
                }
            }
        }),
    {
        name: "MuiPriceFinderStyles"
    }
);
