export const BadgeIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 0.3125C8.96438 0.3125 8.125 1.15188 8.125 2.1875V6.25C8.125 6.4225 8.265 6.5625 8.4375 6.5625H11.5625C11.735 6.5625 11.875 6.4225 11.875 6.25V2.1875C11.875 1.15188 11.0356 0.3125 10 0.3125ZM10 2.8125C9.65469 2.8125 9.375 2.53281 9.375 2.1875C9.375 1.84219 9.65469 1.5625 10 1.5625C10.3453 1.5625 10.625 1.84219 10.625 2.1875C10.625 2.53281 10.3453 2.8125 10 2.8125Z"
            fill="#393F49"
        />
        <path
            d="M19.0625 4.6875H12.5V6.5625C12.5 6.90781 12.2203 7.1875 11.875 7.1875H8.125C7.77969 7.1875 7.5 6.90781 7.5 6.5625V4.6875H0.9375C0.592187 4.6875 0.3125 4.96719 0.3125 5.3125V19.0625C0.3125 19.4078 0.592187 19.6875 0.9375 19.6875H19.0625C19.4078 19.6875 19.6875 19.4078 19.6875 19.0625V5.3125C19.6875 4.96719 19.4078 4.6875 19.0625 4.6875ZM6.5625 9.375C7.42406 9.375 8.125 10.0759 8.125 10.9375C8.125 11.7991 7.42406 12.5 6.5625 12.5C5.70094 12.5 5 11.7991 5 10.9375C5 10.0759 5.70094 9.375 6.5625 9.375ZM9.6875 16.5625H3.4375C3.265 16.5625 3.125 16.4225 3.125 16.25C3.125 14.6994 4.38687 13.4375 5.9375 13.4375H7.1875C8.73812 13.4375 10 14.6994 10 16.25C10 16.4225 9.86 16.5625 9.6875 16.5625ZM16.5625 15H12.1875C12.015 15 11.875 14.86 11.875 14.6875C11.875 14.515 12.015 14.375 12.1875 14.375H16.5625C16.735 14.375 16.875 14.515 16.875 14.6875C16.875 14.86 16.735 15 16.5625 15ZM16.5625 11.875H12.1875C12.015 11.875 11.875 11.735 11.875 11.5625C11.875 11.39 12.015 11.25 12.1875 11.25H16.5625C16.735 11.25 16.875 11.39 16.875 11.5625C16.875 11.735 16.735 11.875 16.5625 11.875Z"
            fill="#393F49"
        />
    </svg>
);
