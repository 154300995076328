import React, { FC, Fragment } from "react";
import { useCookies } from "react-cookie";
import { Button, Card, CardContent, Grid, Grow, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            position: "fixed",
            flexGrow: 1,
            zIndex: 9999,
            top: 64,
            width: "100%"
        },
        contentLock: {
            width: "100vw",
            height: "100vh",
            backgroundColor: theme.palette.grey[700],
            opacity: 0.5,
            zIndex: 9998,
            position: "absolute"
        },
        bannerContainer: {
            display: "flex",
            position: "relative",
            justifyContent: "center"
        },
        cookieBanner: {
            width: "380px",
            maxWidth: "100%",
            margin: "16px 24px"
        },
        descriptionContainer: {
            width: "95%"
        },
        description: {
            fontWeight: 400,
            fontSize: 14
            // color: theme.colors.smashleadsPurple
            // color: theme.colors.white
        },
        linkTitle: {
            fontWeight: 600,
            fontSize: 14,
            textDecoration: "none"
        },
        link: {
            textDecoration: "none"
        }
    })
);

export interface CookieBannerProps {
    cookieName: string;
}

const CookieBanner: FC<CookieBannerProps> = ({ cookieName }) => {
    const classes = useStyles();
    const [cookies, setCookie] = useCookies([cookieName]);
    const onAccept = () => setCookie(cookieName, { required: "ok", analytics: "ok" });
    const onDecline = () => setCookie(cookieName, { required: "ok", analytics: "no" });

    console.log(cookies);

    const settings = {
        title: "Einwilligung zur Datenverarbeitung 🍪",
        description:
            "Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, unsere Website, unsere Software und deine Erfahrung zu verbessern.",
        privacyLink: "https://www.onpreo.com/privacy-policy",
        privacyLinkTitle: "Datenschutz",
        imprintLink: "https://www.onpreo.com/terms-of-service",
        imprintLinkTitle: "Impressum",
        buttonAccept: "Alle Cookies akzeptieren",
        buttonDecline: "Nur Notwendige speichern"
    };

    return (
        <Fragment>
            {!cookies[cookieName] && (
                <Fragment>
                    <div className={classes.contentLock} />
                    <div className={classes.root} style={{ pointerEvents: !cookies[cookieName] ? "auto" : "none" }}>
                        <Grow in={!cookies[cookieName]} timeout={1200}>
                            <div className={classes.bannerContainer}>
                                <Card className={classes.cookieBanner}>
                                    <CardContent>
                                        <div className={classes.descriptionContainer}>
                                            <Typography variant={"h3"}>{settings.title}</Typography>
                                            <Typography className={classes.description}>{settings.description}</Typography>
                                            <a href={settings.imprintLink} target={"__blank"} rel={"noopener noreferrer"} className={classes.link}>
                                                <Typography className={classes.linkTitle}>{settings.imprintLinkTitle}</Typography>
                                            </a>
                                            <a href={settings.privacyLink} target={"__blank"} rel={"noopener noreferrer"} className={classes.link}>
                                                <Typography className={classes.linkTitle}>{settings.privacyLinkTitle}</Typography>
                                            </a>
                                        </div>
                                    </CardContent>
                                    <CardContent style={{ paddingTop: 0, paddingBottom: 16 }}>
                                        <Grid container item justify={"center"} spacing={2} alignItems={"center"}>
                                            <Grid item>
                                                <Button variant={"contained"} color={"primary"} onClick={onAccept}>
                                                    {settings.buttonAccept}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant={"text"} color={"primary"} onClick={onDecline}>
                                                    {settings.buttonDecline}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grow>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default CookieBanner;
