import { ButtonBase, Menu, MenuItem, Grid, makeStyles } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import componentStyles from "./styles";
import { FC, useState } from "react";

const useStyles = makeStyles(componentStyles);

interface TableMenuProps {
    menuOptions?: {
        icons: JSX.Element[];
        label: string[];
        onClick?: (() => void | Promise<boolean>)[];
        mostUsed?: {
            icon: JSX.Element;
            onClick: () => void | Promise<boolean>;
        };
    };
}

const TableMenu: FC<TableMenuProps> = ({ menuOptions }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [hover, setHover] = useState(false);

    const handleMouseOver = () => {
        setHover(true);
    };

    const handleMouseOut = () => {
        setHover(false);
    };

    return (
        <Grid container style={{ width: 70, paddingRight: 0 }}>
            <div className={hover ? classes.buttonGroup : undefined}>
                <ButtonBase
                    aria-describedby="menu"
                    disableRipple
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseOut}
                    className={classes.button}
                    style={{ borderRadius: !!menuOptions?.mostUsed && hover ? "5px 0 0 5px" : 5 }}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        event.nativeEvent.stopImmediatePropagation();
                        setOpen(true);
                        setAnchorEl(event.currentTarget);
                    }}
                >
                    <MoreVertIcon style={{ width: 18, height: 18 }} />
                </ButtonBase>
                {hover && menuOptions.mostUsed && (
                    <ButtonBase
                        disableRipple
                        className={classes.button}
                        style={{ borderRadius: "0 5px 5px 0" }}
                        onMouseOver={handleMouseOver}
                        onMouseLeave={handleMouseOut}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                            menuOptions?.mostUsed?.onClick();
                        }}
                    >
                        {menuOptions?.mostUsed?.icon}
                    </ButtonBase>
                )}
            </div>
            <Menu
                id="menu"
                open={open}
                anchorEl={anchorEl}
                onClose={event => {
                    //@ts-ignore
                    event.stopPropagation();
                    setOpen(false);
                    setAnchorEl(null);
                }}
            >
                {menuOptions?.icons.map((item, ind) => {
                    return (
                        <MenuItem
                            key={ind}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                event.nativeEvent.stopImmediatePropagation();
                                if (menuOptions?.onClick?.[ind]) menuOptions?.onClick[ind]();
                            }}
                        >
                            <Grid container key={ind} alignItems="center">
                                <Grid item>{item}</Grid>
                                <Grid item>{menuOptions?.label[ind]}</Grid>
                            </Grid>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Grid>
    );
};

export default TableMenu;
