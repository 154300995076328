import React, { FC, Fragment } from "react";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
// core components
import UserDropdown, { UserDropdownProps } from "../../dropdowns/user-dropdown";
import componentStyles from "./styles/admin-navbar";
import { Chip, Typography } from "@material-ui/core";
import Phone from "@material-ui/icons/Phone";

const useStyles = makeStyles(componentStyles);

interface PhoneConfig {
    phone?: string;
    demo?: boolean;
    trialDays?: number;
    isTrialing?: boolean;
    show?: boolean;
}

type AdminNavbarProps = UserDropdownProps & PhoneConfig;

export const AdminNavbar: FC<AdminNavbarProps> = ({ userRoutes, user, phone, children, demo, trialDays, isTrialing, show }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <AppBar position={"absolute"} elevation={0} classes={{ root: classes.appBarRoot }}>
                <Toolbar disableGutters>
                    <Container style={{ position: "absolute" }}>
                        <Box
                            display={demo ? "grid" : "flex"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            width={"100%"}
                            marginTop={"1rem"}
                            marginBottom={"1rem"}
                            marginLeft={"17rem"}
                        >
                            {demo && (
                                <Chip
                                    size="small"
                                    label={"DEMO-Dashboard"}
                                    style={{ fontSize: 11, padding: 8, background: "rgb(17,205,239)", maxWidth: 130 }}
                                />
                            )}
                            {isTrialing && (
                                <Typography style={{ fontWeight: 500, fontSize: 15 }}>{`Verbleibende Tage im Testzeitraum: ${trialDays}`}</Typography>
                            )}
                        </Box>
                    </Container>

                    <Container classes={{ root: classes.containerRoot }}>
                        <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"} width={"100%"} marginTop={"1rem"} marginBottom={"1rem"}>
                            {phone && (
                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Typography style={{ fontWeight: 500, fontSize: 15 }}>
                                        <Phone style={{ marginRight: 6, width: 20, height: 20 }} />
                                        {phone}
                                    </Typography>
                                </Box>
                            )}
                            {children}
                            <Box
                                display={"flex"}
                                justifyContent={demo ? "flex-end" : "center"}
                                alignItems={"center"}
                                style={{ minWidth: demo ? 200 : 120, marginLeft: demo ? -40 : 0 }}
                                className={clsx(classes.marginLeftAuto, {
                                    [classes.displayNone]: false
                                })}
                            >
                                <UserDropdown userRoutes={userRoutes} user={user} show={show} />
                            </Box>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
        </Fragment>
    );
};
