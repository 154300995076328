import { Control, Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";
import FilledInput, { FilledInputProps } from "@material-ui/core/FilledInput";
import Typography from "@material-ui/core/Typography";
import React, { FC, Fragment } from "react";
import { useFilledInputStyles } from "./styles";

interface ControlledFilledInputProps<TFieldValues extends FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>> extends FilledInputProps {
    control: Control<TFieldValues, any>;
    // NOTE: useless prop
    errors?: any;
    name: TName;
    required?: boolean;
    label?: string;
    minRows?: number;
    rules?: UseControllerProps<TFieldValues, TName>["rules"];
}

export const ControlledFilledInput = <TFieldValues extends FieldValues>({
    control,
    errors = false,
    name,
    label,
    required,
    rules,
    ...props
}: ControlledFilledInputProps<TFieldValues>) => {
    const classes = useFilledInputStyles();

    return (
        <Fragment>
            {label && (
                <Typography align="left" style={{ fontWeight: 600, fontSize: "14px" }}>
                    {label}
                </Typography>
            )}
            <Controller
                rules={{ required: required ? "Dieses Feld ist ein Pflichtfeld" : undefined, ...rules }}
                control={control}
                name={name}
                render={({ field, fieldState: { error } }) => (
                    <div style={{ position: "relative" }}>
                        <FilledInput
                            {...field}
                            {...props}
                            className={classes.filledInput}
                            required={required}
                            /* type={name} */
                            error={!!error}
                            multiline={!!props.minRows}
                            minRows={props.minRows}
                            maxRows={props.minRows}
                        />
                        {error !== undefined && <Typography className={classes.errorMessage}>{error.message}</Typography>}
                    </div>
                )}
            />
        </Fragment>
    );
};
