import Switch, { SwitchProps } from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { Controller } from "react-hook-form";
import React, { FC, Fragment } from "react";

interface ControlledSwitchProps extends SwitchProps {
    control: any;
    errors?: boolean;
    name: string;
    required?: boolean;
    label?: string;
    padding?: boolean;
}

export const ControlledSwitch: FC<ControlledSwitchProps> = ({ control, errors, name, label, required, padding, ...props }) => {
    return (
        <Fragment>
            {label && <Typography style={{ fontWeight: 600, fontSize: "14px", paddingRight: padding ? 8 : "auto" }}>{label}</Typography>}
            <Controller
                name={name}
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value, ref } }) => (
                    <Switch {...props} checked={value} value={value} onChange={(event, value) => onChange(value)} />
                )}
            />
        </Fragment>
    );
};
