import { RealEstateStatus } from "@onpreo/database/src/definitions/real-estate-helpers";
import theme from "../theme/theme";

export const initialProgress = {
    Valuate_property: "in-progress",
    Evaluation_date: "in-progress",
    Place_brokerage_order: "not-done",
    Preparation_RE_expose: "not-done",
    Complete_basic_data: "not-done",
    Add_missing_contacts: "not-done",
    Grant_warrant: "not-done",
    Book_photo_session: "not-done",
    Upload_energy_certificate: "not-done",
    Expose_in_preparation: "not-done",
    Viewing_appointments_and_buyer_selection: "not-done",
    Viewings_are_arranged: "not-done",
    Select_buyers: "not-done",
    Notary_appointment: "not-done",
    Draft_purchase_agreement_is_created: "not-done",
    Notary_appointment_is_agreed: "not-done",
    Sign_purchase_agreement: "not-done",
    Get_keys: "not-done",
    Key_is_handed_over_to_owner: "not-done"
};

const getProgress = realEstate => {
    if (realEstate?.status === RealEstateStatus.VIEWING_APPOINTMENTS) {
        return {
            ...initialProgress,
            Valuate_property: "done",
            Evaluation_date: "done",
            Place_brokerage_order: "done",
            Preparation_RE_expose: "done",
            Complete_basic_data: "done",
            Add_missing_contacts: "done",
            Grant_warrant: "done",
            Book_photo_session: "done",
            Upload_energy_certificate: "done",
            Expose_in_preparation: "done",
            Viewing_appointments_and_buyer_selection: "in-progress",
            Viewings_are_arranged: "in-progress"
        };
    }

    if (
        realEstate?.status === RealEstateStatus.FEEDBACK_TOUR_ROUND ||
        realEstate?.status === RealEstateStatus.CREATE_NEW_VISIT_ROUND ||
        realEstate?.status === RealEstateStatus.MARKETING_ANALYSIS
    ) {
        return {
            ...initialProgress,
            Valuate_property: "done",
            Evaluation_date: "done",
            Place_brokerage_order: "done",
            Preparation_RE_expose: "done",
            Complete_basic_data: "done",
            Add_missing_contacts: "done",
            Grant_warrant: "done",
            Book_photo_session: "done",
            Upload_energy_certificate: "done",
            Expose_in_preparation: "done",
            Viewing_appointments_and_buyer_selection: "done",
            Viewings_are_arranged: "done",
            Select_buyers: "in-progress"
        };
    }

    if (
        realEstate?.status === RealEstateStatus.BUYER_SELECTED ||
        realEstate?.status === RealEstateStatus.RESERVED ||
        realEstate?.status === RealEstateStatus.COMPLIANCE_CHECK
    ) {
        return {
            ...initialProgress,
            Valuate_property: "done",
            Evaluation_date: "done",
            Place_brokerage_order: "done",
            Preparation_RE_expose: "done",
            Complete_basic_data: "done",
            Add_missing_contacts: "done",
            Grant_warrant: "done",
            Book_photo_session: "done",
            Upload_energy_certificate: "done",
            Expose_in_preparation: "done",
            Viewing_appointments_and_buyer_selection: "done",
            Viewings_are_arranged: "done",
            Select_buyers: "done",
            Notary_appointment: "in-progress",
            Draft_purchase_agreement_is_created: "in-progress"
        };
    }

    if (realEstate?.status === RealEstateStatus.NOTARY_APPOINTMENT_TO_AGREE) {
        return {
            ...initialProgress,
            Valuate_property: "done",
            Evaluation_date: "done",
            Place_brokerage_order: "done",
            Preparation_RE_expose: "done",
            Complete_basic_data: "done",
            Add_missing_contacts: "done",
            Grant_warrant: "done",
            Book_photo_session: "done",
            Upload_energy_certificate: "done",
            Expose_in_preparation: "done",
            Viewing_appointments_and_buyer_selection: "done",
            Viewings_are_arranged: "done",
            Select_buyers: "done",
            Notary_appointment: "done",
            Draft_purchase_agreement_is_created: "done",
            Notary_appointment_is_agreed: "in-progress"
        };
    }

    if (
        realEstate?.status === RealEstateStatus.NOTARY_APPOINTMENT_PENDING ||
        realEstate?.status === RealEstateStatus.POST_APPROVAL_DATE_TO_AGREE ||
        realEstate?.status === RealEstateStatus.POST_APPROVAL_DATE_PENDING
    ) {
        return {
            ...initialProgress,
            Valuate_property: "done",
            Evaluation_date: "done",
            Place_brokerage_order: "done",
            Preparation_RE_expose: "done",
            Complete_basic_data: "done",
            Add_missing_contacts: "done",
            Grant_warrant: "done",
            Book_photo_session: "done",
            Upload_energy_certificate: "done",
            Expose_in_preparation: "done",
            Viewing_appointments_and_buyer_selection: "done",
            Viewings_are_arranged: "done",
            Select_buyers: "done",
            Notary_appointment: "done",
            Draft_purchase_agreement_is_created: "done",
            Notary_appointment_is_agreed: "done",
            Sign_purchase_agreement: "in-progress"
        };
    }

    if (realEstate?.status === RealEstateStatus.HANDOVER_DATE_TO_AGREE) {
        return {
            Valuate_property: "done",
            Evaluation_date: "done",
            Place_brokerage_order: "done",
            Preparation_RE_expose: "done",
            Complete_basic_data: "done",
            Add_missing_contacts: "done",
            Grant_warrant: "done",
            Book_photo_session: "done",
            Upload_energy_certificate: "done",
            Expose_in_preparation: "done",
            Viewing_appointments_and_buyer_selection: "done",
            Viewings_are_arranged: "done",
            Select_buyers: "done",
            Notary_appointment: "done",
            Draft_purchase_agreement_is_created: "done",
            Notary_appointment_is_agreed: "done",
            Sign_purchase_agreement: "done",
            Get_keys: "in-progress",
            Key_is_handed_over_to_owner: "in-progress"
        };
    }

    if (realEstate?.status === RealEstateStatus.CUSTOMER_CANCEL || realEstate?.status === RealEstateStatus.LOST) {
        return initialProgress;
    }

    if (realEstate?.status === RealEstateStatus.SOLD) {
        return {
            Valuate_property: "done",
            Evaluation_date: "done",
            Place_brokerage_order: "done",
            Preparation_RE_expose: "done",
            Complete_basic_data: "done",
            Add_missing_contacts: "done",
            Grant_warrant: "done",
            Book_photo_session: "done",
            Upload_energy_certificate: "done",
            Expose_in_preparation: "done",
            Viewing_appointments_and_buyer_selection: "done",
            Viewings_are_arranged: "done",
            Select_buyers: "done",
            Notary_appointment: "done",
            Draft_purchase_agreement_is_created: "done",
            Notary_appointment_is_agreed: "done",
            Sign_purchase_agreement: "done",
            Get_keys: "done",
            Key_is_handed_over_to_owner: "done"
        };
    }

    return initialProgress;
};

export const makeProgressGuestApp = realEstate => {
    if (realEstate?.status === RealEstateStatus.AGREE_TO_START_PRICE) {
        let newProgress = {
            ...initialProgress
        };

        if (realEstate?.askingPrice?.estimate) {
            newProgress = {
                ...newProgress,
                Valuate_property: "done",
                Evaluation_date: "done",
                Place_brokerage_order: realEstate.oneClickDeal === undefined ? "in-progress" : "done"
            };
        }
        return newProgress;
    }

    if (realEstate?.status === RealEstateStatus.EXPOSE_APPROVAL_OBTAIN || realEstate?.status === RealEstateStatus.EXPOSE_DATA_OBTAIN) {
        let newProgress = {
            ...initialProgress,
            Valuate_property: "done",
            Evaluation_date: "done",
            Place_brokerage_order: realEstate.oneClickDeal === undefined ? "in-progress" : "done",
            Preparation_RE_expose: "in-progress",
            Complete_basic_data: "in-progress"
        };

        if (realEstate?.expose?.basicData) {
            newProgress = {
                ...newProgress,
                Preparation_RE_expose: "done",
                Complete_basic_data: "done",
                Add_missing_contacts: "in-progress"
            };
        }

        if (realEstate?.expose?.importantContacts?.contactInformation) {
            newProgress = {
                ...newProgress,
                Add_missing_contacts: "done",
                Grant_warrant: "in-progress"
            };
        }

        if (realEstate?.expose?.powerOfAttorney?.uploaded) {
            newProgress = {
                ...newProgress,
                Grant_warrant: "done",
                Book_photo_session: "in-progress"
            };
        }

        if (realEstate?.expose?.photoAppointment?.photoAppointmentDetails) {
            newProgress = {
                ...newProgress,
                Book_photo_session: "done",
                Upload_energy_certificate: "in-progress"
            };
        }

        if (realEstate?.energyInfo?.certificate) {
            newProgress = {
                ...newProgress,
                Upload_energy_certificate: "done",
                Expose_in_preparation: "in-progress"
            };
        }

        return newProgress;
    }

    if (realEstate?.status === RealEstateStatus.EXPOSE_CREATE || realEstate?.status === RealEstateStatus.PUT_RE_ON_PORTALS) {
        if (realEstate.oneClickDeal === undefined)
            return {
                ...initialProgress,
                Valuate_property: "done",
                Evaluation_date: "done",
                Place_brokerage_order: "in-progress"
            };

        if (realEstate.oneClickDeal !== undefined)
            return {
                ...initialProgress,
                Valuate_property: "done",
                Evaluation_date: "done",
                Place_brokerage_order: "done",
                Preparation_RE_expose: "done",
                Complete_basic_data: "done",
                Add_missing_contacts: "done",
                Grant_warrant: realEstate.expose?.powerOfAttorney?.uploaded === true ? "done" : "in-progress",
                Book_photo_session: "done",
                Upload_energy_certificate: realEstate.energyInfo?.certificate !== undefined ? "done" : "in-progress",
                Expose_in_preparation: "done",
                Viewing_appointments_and_buyer_selection: "in-progress",
                Viewings_are_arranged: "in-progress"
            };
    }

    return getProgress(realEstate);
};

export const setProgressColor = progress => {
    if (progress === "done") return "#B0EED3";
    if (progress === "in-progress") return "#AAEDF9";
    if (progress === "not-done") return "#E9ECEF";
    if (progress === "pending") return theme.palette.warning.main;
};

export const disabledSelectButtons = realEstate => {
    return {
        AGREE_TO_START_PRICE: false,
        EXPOSE_DATA_OBTAIN: realEstate?.oneClickDeal?.dealConfirmed ? false : true,
        EXPOSE_APPROVAL_OBTAIN:
            realEstate?.expose?.basicData &&
            realEstate?.expose?.importantContacts?.contactInformation &&
            realEstate?.expose?.powerOfAttorney?.uploaded &&
            realEstate?.expose?.photoAppointment?.photoAppointmentDetails &&
            realEstate?.energyInfo?.certificate
                ? false
                : true,
        EXPOSE_CREATE: realEstate?.expose?.status === "in-process" || realEstate?.expose?.status === "present" ? false : true,
        PUT_RE_ON_PORTALS: realEstate?.expose?.status === "present" ? false : true,
        VIEWING_APPOINTMENTS: realEstate?.appointments ? false : true,
        FEEDBACK_TOUR_ROUND: realEstate?.appointments ? false : true,
        CREATE_NEW_VISIT_ROUND: true, // not MVP
        MARKETING_ANALYSIS: true, // not MVP
        BUYER_SELECTED: false, // set manually
        RESERVED: true, // not MVP
        COMPLIANCE_CHECK: true, // not MVP
        // NOTARY_APPOINTMENT_TO_AGREE: realEstate?.notary ? false : true, // set manually
        // NOTARY_APPOINTMENT_PENDING: realEstate?.notary ? false : true,
        NOTARY_APPOINTMENT_TO_AGREE: false, // set manually
        NOTARY_APPOINTMENT_PENDING: false,
        POST_APPROVAL_DATE_TO_AGREE: true, // not MVP
        POST_APPROVAL_DATE_PENDING: true, // not MVP
        CUSTOMER_CANCEL: false,
        HANDOVER_DATE_TO_AGREE: realEstate?.notary ? false : true, // set manually
        SOLD: false,
        LOST: false
    };
};

export const salesProgressSelectData = [
    { value: "AGREE_TO_START_PRICE", title: "Startpreis vereinbaren" },
    { value: "EXPOSE_DATA_OBTAIN", title: "Exposé-Daten einholen" },
    { value: "EXPOSE_APPROVAL_OBTAIN", title: "Exposé-Freigabe einholen" },
    { value: "EXPOSE_CREATE", title: "Exposé erstellen" },
    { value: "PUT_RE_ON_PORTALS", title: "Immobilie auf Portale stellen" },
    { value: "VIEWING_APPOINTMENTS", title: "Besichtigungstermine" },
    { value: "FEEDBACK_TOUR_ROUND", title: "Feedback Besichtigungsrunde" },
    // { value: "CREATE_NEW_VISIT_ROUND", title: "Neue Besichtigungsrunde anlegen" },
    // { value: "MARKETING_ANALYSIS", title: "Vermarktungsanalyse" },
    { value: "BUYER_SELECTED", title: "Käufer ausgewählt" },
    // { value: "RESERVED", title: "Reserviert" },
    // { value: "COMPLIANCE_CHECK", title: "Compliance Check" },
    { value: "NOTARY_APPOINTMENT_TO_AGREE", title: "Notartermin zu vereinbaren" },
    { value: "NOTARY_APPOINTMENT_PENDING", title: "Notartermin ausstehend" },
    // { value: "POST_APPROVAL_DATE_TO_AGREE", title: "Nachgenehmigungstermin zu vereinbaren" },
    // { value: "POST_APPROVAL_DATE_PENDING", title: "Nachgenehmigungstermin ausstehend" },
    { value: "CUSTOMER_CANCEL", title: "Kunde möchte kündigen" },
    { value: "HANDOVER_DATE_TO_AGREE", title: "Übergabetermin vereinbaren" },
    { value: "SOLD", title: "Verkauft" },
    { value: "LOST", title: "Verloren" }
];

export const mapSelectValueToLabel = {
    AGREE_TO_START_PRICE: "Startpreis vereinbaren",
    EXPOSE_DATA_OBTAIN: "Exposé-Daten einholen",
    EXPOSE_APPROVAL_OBTAIN: "Exposé-Freigabe einholen",
    EXPOSE_CREATE: "Exposé erstellen",
    PUT_RE_ON_PORTALS: "Immobilie auf Portale stellen",
    VIEWING_APPOINTMENTS: "Besichtigungstermine",
    FEEDBACK_TOUR_ROUND: "Feedback Besichtigungsrunde",
    // CREATE_NEW_VISIT_ROUND: true, // not MVP
    // MARKETING_ANALYSIS: true, // not MVP
    BUYER_SELECTED: "Käufer ausgewählt", // set manually
    // RESERVED: true, // not MVP
    // COMPLIANCE_CHECK: true, // not MVP
    // NOTARY_APPOINTMENT_TO_AGREE: realEstate?.notary ? false : true, // set manually
    // NOTARY_APPOINTMENT_PENDING: realEstate?.notary ? false : true,
    NOTARY_APPOINTMENT_TO_AGREE: "Notartermin zu vereinbaren", // set manually
    NOTARY_APPOINTMENT_PENDING: "Notartermin ausstehend",
    // POST_APPROVAL_DATE_TO_AGREE: true, // not MVP
    // POST_APPROVAL_DATE_PENDING: true, // not MVP
    CUSTOMER_CANCEL: "Kunde möchte kündigen",
    HANDOVER_DATE_TO_AGREE: "Übergabetermin vereinbaren", // set manually
    SOLD: "Verkauft",
    LOST: "Verloren"
};

export const makeProgressBrokerApp = realEstate => {
    if (realEstate?.status === RealEstateStatus.AGREE_TO_START_PRICE) {
        let newProgress = {
            ...initialProgress,
            Place_brokerage_order: realEstate.oneClickDeal === undefined ? "not-done" : "done"
        };

        return newProgress;
    }

    if (realEstate?.status === RealEstateStatus.EXPOSE_APPROVAL_OBTAIN || realEstate?.status === RealEstateStatus.EXPOSE_DATA_OBTAIN) {
        let newProgress = {
            ...initialProgress,
            Valuate_property: "done",
            Evaluation_date: "done",
            Place_brokerage_order: realEstate.oneClickDeal?.dealConfirmed === false ? "pending" : "done",
            Preparation_RE_expose: "in-progress",
            Complete_basic_data: "in-progress"
        };

        if (realEstate?.expose?.basicData) {
            newProgress = {
                ...newProgress,
                Preparation_RE_expose: "done",
                Complete_basic_data: "done",
                Add_missing_contacts: "in-progress"
            };
        }

        if (realEstate?.expose?.importantContacts?.contactInformation) {
            newProgress = {
                ...newProgress,
                Add_missing_contacts: "done",
                Grant_warrant: "in-progress"
            };
        }

        if (realEstate?.expose?.powerOfAttorney?.uploaded) {
            newProgress = {
                ...newProgress,
                Grant_warrant: "done",
                Book_photo_session: "in-progress"
            };
        }

        if (realEstate?.expose?.photoAppointment?.photoAppointmentDetails) {
            newProgress = {
                ...newProgress,
                Book_photo_session: "done",
                Upload_energy_certificate: "in-progress"
            };
        }

        if (realEstate?.energyInfo?.certificate) {
            newProgress = {
                ...newProgress,
                Upload_energy_certificate: "done",
                Expose_in_preparation: "in-progress"
            };
        }

        return newProgress;
    }

    if (realEstate?.status === RealEstateStatus.EXPOSE_CREATE || realEstate?.status === RealEstateStatus.PUT_RE_ON_PORTALS) {
        return {
            ...initialProgress,
            Valuate_property: "done",
            Evaluation_date: "done",
            Place_brokerage_order: realEstate.oneClickDeal === undefined ? "pending" : "done",
            Preparation_RE_expose: "done",
            Complete_basic_data: "done",
            Add_missing_contacts: "done",
            Grant_warrant: realEstate.expose?.powerOfAttorney?.uploaded === true ? "done" : "in-progress",
            Book_photo_session: "done",
            Upload_energy_certificate: realEstate.energyInfo?.certificate !== undefined ? "done" : "in-progress",
            Expose_in_preparation: "done",
            Viewing_appointments_and_buyer_selection: "in-progress",
            Viewings_are_arranged: "in-progress"
        };
    }

    return getProgress(realEstate);
};
