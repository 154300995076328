import { Controller } from "react-hook-form";
import { ButtonBase, FilledInput, FilledInputProps, InputBase, Menu, OutlinedInput, Typography } from "@material-ui/core";
import React, { FC, Fragment, KeyboardEvent, useMemo, useState } from "react";
import { useFilledInputStyles } from "./styles";
import { makeStyles } from "@material-ui/core/styles";
import { ColorResult, SketchPicker } from "react-color";
import tinycolor from "tinycolor2";
import { useDebouncedInput } from "../hooks/use-debounced-input";
import InputAdornment from "@material-ui/core/InputAdornment";

export type Color = string;

/**
 * Tries to create a color from a generic input
 * @param {string} input
 * @returns {Result<Color, "invalid_color">}
 */
export const colorFromAny = (input: string): Color | undefined => {
    const parsed = tinycolor(input);
    if (parsed.isValid()) {
        if (parsed.getAlpha() === 1) return parsed.toHexString();
        else return parsed.toHex8String();
    }
    return undefined;
};

/**
 * Convert any color to rgba
 * @param {Color} color
 * @returns {Result<string, "invalid_color">}
 */
export const colorToRgba = (color?: Color): string | Error => {
    if (color === undefined) return Error("invalid_color" as const);
    const parsed = tinycolor(color);
    if (parsed.isValid()) return parsed.toRgbString();
    else return Error("invalid_color" as const);
};

export const colorToRgbaObject = (color?: Color): undefined | { r: number; b: number; g: number; a?: number } => {
    if (color === undefined) return undefined;
    const parsed = tinycolor(color);
    if (parsed.isValid()) return parsed.toRgb();
    else return undefined;
};

const useHiddenMenu = makeStyles({
    paper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        backgroundColor: "transparent",
        width: 244,
        height: 328
    }
});

const INPUT_HEIGHT = 46;
const INPUT_WIDTH = 64;
const useStyles = makeStyles(muiTheme => ({
    label: {
        position: "absolute",
        // left: muiTheme.spacing(2),
        // Bottom Margin + Height of the Input + Space above input
        // bottom: 8 + INPUT_HEIGHT + 4,
        color: "#90919F"
    },
    input: {
        textAlign: "center"
    },
    button: {
        height: INPUT_HEIGHT,
        width: INPUT_WIDTH,
        minWidth: INPUT_WIDTH,
        borderRadius: "4px 0px 0px 4px",
        borderRight: "1px solid " + muiTheme.palette.grey[300],
        marginLeft: -12
    }
}));

interface ColorInputProps {
    value: string;
    disabled?: boolean;
    onChange(color: Color): void;

    /* Optional Parameter, defaults to false, can be set if the number input should not have the white background */
    invertColor?: boolean;
}

interface ControlledColorPickerProps extends FilledInputProps {
    control: any;
    errors: boolean;
    name: string;
    required?: boolean;
    label?: string;
    minRows?: number;
}

const ColorInput = ({ value, onChange, errors, name, label, required, ...props }) => {
    const classes = { ...useFilledInputStyles(), ...useStyles() };

    const { currentValue, updateValue, updateFromEventImmediate, updateFromEvent } = useDebouncedInput<string>(value, onChange, {
        map: input => colorFromAny(input)
    });

    const hiddenMenu = useHiddenMenu();

    const [anchorPicker, setAnchorPicker] = useState<null | HTMLElement>(null);
    const handleColorOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorPicker(event.currentTarget);
    const handleClose = () => setAnchorPicker(null);
    const handleChange = (color: ColorResult) => {
        console.log(color.rgb.a, color.rgb.a !== undefined ? Math.round(color.rgb.a * 255).toString(16) : "");
        updateValue(
            color.hex +
                (color.rgb.a !== undefined
                    ? Math.round(color.rgb.a * 255)
                          .toString(16)
                          .padStart(2, "0")
                    : "")
        );
    };
    // used so that the user can confirm a color entry be pressing enter
    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.currentTarget.blur();
            updateValue(event.currentTarget.value);
        }
    };
    // const handleChangeComplete = (color: ColorResult) => updateValue(color);
    const rgbaColor = useMemo(() => colorToRgbaObject(currentValue), [currentValue]);

    return (
        <Fragment>
            {label && <Typography style={{ fontWeight: 600, fontSize: "14px" }}>{label}</Typography>}
            <FilledInput
                {...props}
                disabled={props.disabled}
                value={currentValue ?? ""}
                onChange={updateFromEventImmediate}
                onBlur={updateFromEvent}
                onKeyDown={handleKeyDown}
                classes={{ input: classes.input }}
                className={classes.filledInput}
                startAdornment={
                    <ButtonBase
                        disableRipple
                        disabled={props.disabled}
                        disableTouchRipple
                        style={{ backgroundColor: currentValue }}
                        className={classes.button}
                        onClick={handleColorOpen}
                    />
                }
            />
            <Menu id="color-menu" anchorEl={anchorPicker} keepMounted open={Boolean(anchorPicker)} onClose={handleClose} elevation={0} classes={hiddenMenu}>
                {rgbaColor && <SketchPicker color={rgbaColor} onChange={handleChange} onChangeComplete={handleChange} />}
            </Menu>
        </Fragment>
    );
};

export const ControlledColorPicker: FC<ControlledColorPickerProps> = ({ control, errors, name, label, required, ...props }) => {
    return (
        <Fragment>
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <ColorInput
                        {...field}
                        {...props}
                        placeholder={props.placeholder}
                        errors={!!errors}
                        label={label}
                        autoComplete="off"
                        required={required}
                        type={name}
                        error={errors}
                    />
                )}
            />
        </Fragment>
    );
};
