import * as yup from "yup";

/*
NOTE: we can either use inbuilt react hook form controller rules validation or an external resolver like yup. Not both together!
PROS of using YUP: Flexible validation in comparison to inbuilt react hook form rules
CONS of using YUP: Need to add a separate validation input in the validation schema for all required inputs since reusable input 
components required property is overridden by yup validator. 

Alternately we can create reusable yup resolver component for eg onboardingSchema , loginSchema, and so on just like reusable 
inputs and then use them for the respective pages.
*/

const phoneRegExp = /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{2,3}[-\s\.]?[0-9]{4,8}$/;
const zipRegExp = /[0-9]{4,5}/;

const companyInfoSchema = yup.object().shape({
    company: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    street: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    houseNumber: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    zip: yup
        .string()
        .min(4, "Die Eingabe darf nur 4-5 Zeichen lang sein")
        .max(5, "Die Eingabe darf nur 4-5 Zeichen lang sein")
        .matches(zipRegExp, "Die Eingabe kann nur Zahlen sein")
        .required("Eintrag kann nicht leer gelassen werden"),
    town: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    nation: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    fixedProvision: yup
        .number()
        .nullable()
        .transform((v, o) => (o === "" ? 0 : v))
        .typeError("Die Eingabe kann nur Zahlen sein")
        .required("Eintrag kann nicht leer gelassen werden"),
    buyerCommission: yup
        .number()
        .nullable()
        .transform((v, o) => (o === "" ? 0 : v))
        .typeError("Die Eingabe kann nur Zahlen sein")
        .required("Eintrag kann nicht leer gelassen werden"),
    sellerCommission: yup
        .number()
        .nullable()
        .transform((v, o) => (o === "" ? 0 : v))
        .typeError("Die Eingabe kann nur Zahlen sein")
        .required("Eintrag kann nicht leer gelassen werden"),
    profilePhone: yup.string().matches(phoneRegExp, "Bitte geben Sie eine gültige Telefonnummer ein").required("Eintrag kann nicht leer gelassen werden")
});

const contactSchema = yup.object().shape({
    firstNameContract: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    lastNameContract: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    emailContract: yup.string().email("Bitte geben Sie eine gültige E-Mail-Adresse ein").required("Eintrag kann nicht leer gelassen werden"),
    phoneContract: yup.string().matches(phoneRegExp, "Bitte geben Sie eine gültige Telefonnummer ein").required("Eintrag kann nicht leer gelassen werden"),
    firstNameOperational: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    lastNameOperational: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    emailOperational: yup.string().email("Bitte geben Sie eine gültige E-mail ein").required("Eintrag kann nicht leer gelassen werden"),
    phoneOperational: yup
        .string()
        .matches(phoneRegExp, "Bitte geben Sie eine gültige Telefonnummer ein")
        .required("Eintrag kann nicht leer gelassen werden")
});

const publicationSchema = yup.object().shape({
    salutation: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    firstName: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    lastName: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    role: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    email: yup.string().email("Bitte geben Sie eine gültige E-mail ein").required("Eintrag kann nicht leer gelassen werden"),
    phone: yup
        .string()
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .matches(phoneRegExp, "Bitte geben Sie eine gültige Telefonnummer ein"),
    mobile: yup.string().matches(phoneRegExp, "Bitte geben Sie eine gültige Telefonnummer ein").required("Eintrag kann nicht leer gelassen werden"),
    slogan: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    primary: yup.string().required("Eintrag kann nicht leer gelassen werden"),
    secondary: yup.string().required("Eintrag kann nicht leer gelassen werden")
});

const landingPageSchema = yup.object().shape({
    soldRealEstate: yup.number().typeError("Die Eingabe kann nur Zahlen sein").required("Eintrag kann nicht leer gelassen werden"),
    yearsExperience: yup.number().typeError("Die Eingabe kann nur Zahlen sein").required("Eintrag kann nicht leer gelassen werden"),
    customerSatisfaction: yup.number().typeError("Die Eingabe kann nur Zahlen sein").required("Eintrag kann nicht leer gelassen werden"),
    aboutUs: yup.string().required("Eintrag kann nicht leer gelassen werden")
});

// empty schema object for stripe and portal tab
const defaultEmptySchema = yup.object().shape({});

const validatorSchemas = {
    companyInfoSchema,
    contactSchema,
    publicationSchema,
    landingPageSchema,
    defaultEmptySchema
};

export default validatorSchemas;
