import React, { ElementType, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import { useRouter } from "next/router";
import { createStyles } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const useStyles = makeStyles(theme =>
    createStyles({
        buttonRoot: {
            padding: ".25rem 0 .25rem 1rem",
            border: "none",
            boxShadow: "none!important",
            [theme.breakpoints.down("md")]: {
                padding: 0,
                // minWidth: "unset!important",
                borderRadius: "50%"
            }
        },
        buttonLabel: {
            fontSize: ".875rem",
            fontWeight: 600,
            // color: theme.palette.buttonLightLabel.main,
            // textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
                padding: 0
            }
        },
        avatarRoot: {
            width: "36px",
            height: "36px",
            marginRight: "0.5rem",
            [theme.breakpoints.down("md")]: {
                marginRight: 0
            }
        },
        dividerRoot: {
            height: 0,
            margin: ".5rem 0",
            overflow: "hidden"
            // borderTop: "1px solid " + theme.palette.gray[200]
        },
        menuTitle: {
            margin: 0,
            // textTransform: "uppercase",
            display: "block",
            padding: ".5rem 1rem"
            // whiteSpace: "nowrap"
        }
    })
);

export interface UserRoute {
    name?: string;
    icon?: ElementType;
    path?: string;
    divider?: boolean;
    isExternal?: boolean;
}

export interface UserDropdownProps {
    userRoutes: UserRoute[];
    user?: {
        name?: string;
        imgSrc?: string;
        imgAlt?: string;
    };
    show?: boolean;
}

export default function UserDropdown({ userRoutes, user, show }: UserDropdownProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const router = useRouter();

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = async (route?: string) => {
        if (route) await router.push(route);
        setAnchorEl(null);
    };

    const menuId = "dropdowns-user-dropdown-id";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={(e, r) => handleMenuClose(undefined)}
        >
            {userRoutes.map(route => (
                <div key={route.path}>
                    {!route.divider && route.name === "Marketing Contents" && show && (
                        <Box display="flex!important" alignItems="center!important" component={MenuItem} onClick={() => handleMenuClose(route.path)}>
                            <Box component={route.icon} width="1.25rem!important" height="1.25rem!important" marginRight="1rem" />
                            <span>{route.name}</span>
                        </Box>
                    )}
                    {!route.divider && !route.isExternal && route.name !== "Marketing Contents" && (
                        <Box display="flex!important" alignItems="center!important" component={MenuItem} onClick={() => handleMenuClose(route.path)}>
                            <Box component={route.icon} width="1.25rem!important" height="1.25rem!important" marginRight="1rem" />
                            <span>{route.name}</span>
                        </Box>
                    )}{" "}
                    {!route.divider && route.isExternal && route.name !== "Marketing Contents" && (
                        <a href={route.path} target={"__blank"} rel="noreferrer noopener">
                            <Box display="flex!important" alignItems="center!important" component={MenuItem}>
                                <Box component={route.icon} width="1.25rem!important" height="1.25rem!important" marginRight="1rem" />
                                <span>{route.name}</span>
                            </Box>
                        </a>
                    )}
                    {route.divider && <Divider component="div" classes={{ root: classes.dividerRoot }} />}
                </div>
            ))}
        </Menu>
    );

    return (
        <Fragment>
            <Button
                // edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                classes={{
                    label: classes.buttonLabel,
                    root: classes.buttonRoot
                }}
            >
                <ChevronLeft />
                {user?.imgSrc && (
                    <Avatar
                        alt={user?.imgAlt ?? "..."}
                        src={user?.imgSrc ?? "/favicon.png"}
                        classes={{
                            root: classes.avatarRoot
                        }}
                    />
                )}
                <Hidden mdDown>{user?.name ?? "insert:username"}</Hidden>
            </Button>
            {renderMenu}
        </Fragment>
    );
}
