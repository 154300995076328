import { Control, Controller, FieldValues, Path, UseControllerProps } from "react-hook-form";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select, { SelectProps } from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import React, { Fragment, useState } from "react";
import { useFilledInputStyles } from "./styles";
import { Checkbox, Grid, InputLabel } from "@material-ui/core";

interface ControlledSelectProps<TFieldValues extends FieldValues, TName extends Path<TFieldValues>> extends SelectProps {
    control: Control<TFieldValues>;
    errors?: boolean;
    disableError?: boolean;
    defaultValue?: string;
    placeholder?: string;
    name: TName;
    required?: boolean;
    label?: string;
    menuItems?: any[];
    rules?: UseControllerProps<TFieldValues, TName>["rules"];
}

export const ControlledSelect = <TFieldValues extends FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>({
    control,
    errors,
    placeholder,
    disableError,
    defaultValue,
    name,
    label,
    required,
    rules,
    ...props
}: ControlledSelectProps<TFieldValues, TName>) => {
    const classes = useFilledInputStyles();
    const defaultChangeNameList = ["lastRenovated.dach", "lastRenovated.heizung", "lastRenovated.fassade", "maintenanceYear"];

    const requiredRule = required ? { required: "Dieses Feld ist erforderlich" } : {};
    return (
        <Fragment>
            {label && (
                <Typography align="left" style={{ fontWeight: 600, fontSize: "14px" }}>
                    {label}
                </Typography>
            )}
            <Controller
                control={control}
                name={name}
                // @ts-ignore
                defaultValue={defaultChangeNameList.includes(name) ? undefined : defaultValue ?? "default"}
                rules={{ ...rules, ...requiredRule }}
                render={({ field, fieldState: { error } }) => (
                    <div style={{ position: "relative" }}>
                        <Select
                            {...field}
                            {...props}
                            placeholder={placeholder}
                            displayEmpty
                            required={required}
                            type={name}
                            error={!!error}
                            variant={"outlined"}
                            input={<FilledInput className={classes.filledInput} />}
                        />
                        {!disableError && error !== undefined && <Typography className={classes.errorMessage}>{error.message}</Typography>}
                    </div>
                )}
            />
        </Fragment>
    );
};

const to = (v: any) => (v === undefined ? "" : !!v ? "yes" : "no");
const from: SelectProps["onChange"] = e => {
    return e.target.value === "yes" ? true : e.target.value === "no" ? false : undefined;
};

export const ControlledBooleanSelect = <TFieldValues extends FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>({
    control,
    errors,
    placeholder,
    name,
    label,
    required = true,
    ...props
}: ControlledSelectProps<TFieldValues, TName>) => {
    const classes = useFilledInputStyles();

    return (
        <Fragment>
            {label && <Typography style={{ fontWeight: 600, fontSize: "14px" }}>{label}</Typography>}
            <Controller
                control={control}
                name={name}
                rules={{
                    validate: required ? v => (v === undefined ? "Dieses Feld ist ein Pflichtfeld" : undefined) : undefined
                }}
                render={({ field: { value, onChange, ...field }, fieldState: { error } }) => {
                    return (
                        <div style={{ position: "relative" }}>
                            <Select
                                {...field}
                                {...props}
                                value={to(value)}
                                onChange={(e, c) => onChange(from(e, c))}
                                //placeholder={placeholder}
                                required={required}
                                error={!!error}
                                variant={"outlined"}
                                displayEmpty
                                input={<FilledInput className={classes.filledInput} />}
                            >
                                <MenuItem disabled hidden value={""}>
                                    Bitte auswählen
                                </MenuItem>
                                <MenuItem value={"yes"}>Ja</MenuItem>
                                <MenuItem value={"no"}>Nein</MenuItem>
                            </Select>
                            {error !== undefined && <Typography className={classes.errorMessage}>{error.message}</Typography>}
                        </div>
                    );
                }}
            />
        </Fragment>
    );
};

const convert = (v: any) => (v === undefined ? [] : v);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP
        }
    },
    getContentAnchorEl: null
};

export const ControlledMultipleSelect = <TFieldValues extends FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>({
    control,
    errors,
    placeholder,
    defaultValue,
    name,
    label,
    required,
    menuItems,
    ...props
}: ControlledSelectProps<TFieldValues, TName>) => {
    const classes = useFilledInputStyles();

    return (
        <Fragment>
            {label && <Typography style={{ fontWeight: 600, fontSize: "14px" }}>{label}</Typography>}
            <Controller
                control={control}
                name={name}
                //@ts-ignore
                defaultValue={defaultValue}
                rules={{ required: required ? "Dieses Feld ist ein Pflichtfeld" : undefined }}
                render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
                    <div style={{ position: "relative" }}>
                        {convert(value).length === 0 && (
                            <InputLabel id="placeholder" shrink={!(convert(value).length === 0)} className={classes.label}>
                                Bitte wählen{" "}
                            </InputLabel>
                        )}
                        <Select
                            {...field}
                            {...props}
                            placeholder={placeholder}
                            multiple
                            value={convert(value)}
                            error={!!error}
                            variant={"outlined"}
                            input={<FilledInput className={classes.filledInput} />}
                            onChange={onChange}
                            MenuProps={MenuProps}
                            renderValue={(selected: any) => selected.join(", ")}
                        >
                            {menuItems.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index} className={classes.menu}>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            style={{ paddingLeft: 16, paddingRight: 12 }}
                                        >
                                            <Typography style={{ marginTop: 7, fontSize: 14 }}>{item}</Typography>
                                            <Checkbox size="small" color="primary" checked={convert(value).indexOf(item) > -1} />
                                        </Grid>{" "}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {error !== undefined && <Typography className={classes.errorMessage}>{error.message}</Typography>}
                    </div>
                )}
            />
        </Fragment>
    );
};
