import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

interface UseDebouncedInputOptions<T> {
    /**
     * Optional function that is applied to values before update occurs (used for something like clamping) can fail if the input is not valid
     */
    map?: (value: T) => T | any;
    /**
     * Required if the T !== string and the FromEvent API should be used
     * If the result of the parsing is undefined, the event will be ignored
     */
    parse?: (value: string) => T | any;
    debounceWait?: number;
    /**
     * Check if the value is equal to the new value, before calling updateExternal/onChange, defaults to true
     * Might be useful as false if the value needs to be actively applied to become valid
     */
    checkEquals?: boolean;
}

/**
 * Type compatible to most react synthetic events
 */
type ReactEvent = { target: { value: string } };

interface UseDebouncedInputResult<T> {
    currentValue: T;
    /**
     * Function that should be called, when
     */
    updateValue: (value: T | ((value: T) => T)) => void;
    /**
     * Same as a updateValue, but takes an React Event
     */
    updateFromEvent: (event: ReactEvent) => void;
    /**
     * Same as updateValue, but skips validation and mapping and does not call onChange
     *
     * This is useful in cases like the color input, where we want to allow the user to type at first and validate onBlur
     */
    updateValueImmediate: (value: T) => void;
    /**
     * Same as updateValueImmediate, but takes an React Event
     */
    updateFromEventImmediate: (event: ReactEvent) => void;
}

export const useDebouncedInput = <T extends {}>(
    value: T,
    onChange: (value: T) => void,
    options: UseDebouncedInputOptions<T> = {}
): UseDebouncedInputResult<T> => {
    const { map = v => v, parse, debounceWait = 200, checkEquals = true } = options;

    const [currentValue, setInternalValue] = useState(value);
    useEffect(() => {
        if (value !== currentValue) setInternalValue(value);
    }, [value]);

    const updateExternalState = useDebouncedCallback((newValue: T) => {
        if (checkEquals && newValue !== value) onChange(newValue);
        else if (!checkEquals) onChange(newValue);
    }, debounceWait);

    const updateValueImmediate = useCallback((value: T) => {
        setInternalValue(value);
    }, []);

    const updateFromEventImmediate = useCallback((event: ReactEvent) => {
        // If the value is already a string casting is safe
        if (typeof value === "string") {
            updateValueImmediate(event.target.value as unknown as T);
        } else {
            if (parse === undefined) throw new Error("[useDebouncedInput] FromEventImmediate is used, but no parse function is supplied");
            const value = parse(event.target.value);
            if (value !== undefined) updateValueImmediate(value);
        }
    }, []);

    const updateValue = useCallback(
        (value: T | ((value: T) => T)) => {
            setInternalValue(prev => {
                const mapped = map(value instanceof Function ? value(prev) : value);
                updateExternalState(mapped);
                return mapped;
            });
        },
        [map, currentValue]
    );

    const updateFromEvent = useCallback(
        (event: ReactEvent) => {
            // If the value is already a string casting is safe
            if (typeof value === "string") {
                updateValue(event.target.value as unknown as T);
            } else {
                if (parse === undefined) throw new Error("[useDebouncedInput] FromEvent is used, but no parse function is supplied");
                const value = parse(event.target.value);
                if (value !== undefined) {
                    updateValue(value);
                }
            }
        },
        [updateValue]
    );

    return { currentValue, updateValue, updateFromEvent, updateValueImmediate, updateFromEventImmediate };
};
