import React, { FC, Fragment } from "react";
import { Box, Typography, useMediaQuery, useTheme, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

interface PageHeaderProps {
    title: string;
    subtitle?: string;
    margin?: number;
    info?: string | React.ReactNode;
    clickableInfo?: boolean;
}

export const PageHeader: FC<PageHeaderProps> = ({ title, subtitle, margin = 8, info, clickableInfo = false, ...props }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

    return (
        <Fragment>
            <Box justifyContent={"flex-start"}>
                <Typography variant={"h1"} color="secondary" style={{ fontSize: isMobile ? 28 : 44 }}>
                    {title}{" "}
                    {info &&
                        (clickableInfo ? (
                            <Tooltip interactive title={info}>
                                <span style={{ cursor: "pointer" }}>
                                    <InfoOutlined style={{ width: 20, height: 20, marginLeft: 8 }} />
                                </span>
                            </Tooltip>
                        ) : (
                            <Tooltip title={info}>
                                <InfoOutlined style={{ width: 20, height: 20, marginLeft: 8 }} />
                            </Tooltip>
                        ))}
                </Typography>
            </Box>
            <Box justifyContent={"flex-start"} marginBottom={margin}>
                {subtitle && <Typography color="secondary">{subtitle}</Typography>}
            </Box>
        </Fragment>
    );
};
