import { Button, createStyles, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Typography } from "@material-ui/core";
import HelpOutline from "@material-ui/icons/HelpOutline";
import * as React from "react";
import { LoadingButton, useToggle } from "..";

const useStyles = makeStyles(theme =>
    createStyles({
        helpIcon: {
            width: 82,
            height: 82
        },
        paper: { minWidth: "512px", minHeight: "320px" },
        deleteText: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "33px",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            color: "#595959"
        },
        deleteTextGrid: {
            justifyContent: "center"
        },
        deleteTextSummary: {
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "14px",
            lineHeight: "19px",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            color: "#000000"
        },
        deleteTextSummaryGrid: {
            justifyContent: "center",
            marginTop: theme.spacing(2)
        },
        actionButtonsGrid: {
            width: 500,
            justifyContent: "center"
        },
        agreeButtonText: {
            fontSize: "14px",
            fontWeight: 400
        },
        deleteButtonText: {
            color: "#212529",
            fontSize: "14px",
            fontWeight: 600
        }
    })
);

type ConfirmDialogProps = {
    // Is the dialog currently opened
    open: boolean;
    /**
     * The callback executed when the user is sure to do it
     */
    onConfirm: () => Promise<any>;

    /**
     * Called when the user wants to cancel the action
     */
    onCancel: () => void;

    // a custom text to display
    heading: React.ReactNode;

    // an optional summary description
    summary?: React.ReactNode;
    confirmButtonText?: string;
};

/**
 * A reusable dialog that can be used, when a decision is of such great importance
 * that we need to extra annoy the user
 *
 * NOTE: The open state of this component is controlled externally, since we dont want to require a certain
 *       form or shape of trigger at this stage
 */
export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, onConfirm, onCancel, heading, summary, confirmButtonText }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.paper }}
        >
            <DialogTitle id="alert-dialog-title">
                <Grid container justifyContent={"center"}>
                    <HelpOutline color="action" className={classes.helpIcon} />
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid container className={classes.deleteTextGrid}>
                        <Typography className={classes.deleteText}>{heading}</Typography>
                    </Grid>
                    {summary !== undefined && (
                        <Grid container className={classes.deleteTextSummaryGrid}>
                            <Typography className={classes.deleteTextSummary}>{summary}</Typography>
                        </Grid>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogContent>
                <Grid container spacing={2} justifyContent={"space-between"} direction={"row-reverse"}>
                    <Grid item>
                        <LoadingButton variant="contained" color="primary" onClick={onConfirm}>
                            <Typography className={classes.agreeButtonText}>{confirmButtonText ?? "Bestätigen"}</Typography>
                        </LoadingButton>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={onCancel} autoFocus>
                            <Typography className={classes.deleteButtonText}>Abbrechen</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
