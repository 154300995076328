import React, { FC, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import TagManager from "react-gtm-module";

interface CookieTrackerProps {
    cookieName: string;
    gtmId: string;
}

export const Tracker: FC<CookieTrackerProps> = ({ cookieName, gtmId }) => {
    // const initialized = useRef(false);
    const [cookies] = useCookies([cookieName]);

    useEffect(() => {
        activateTracking();
    }, [cookies[cookieName]]);

    const activateTracking = () => {
        // const addTracking = cookies[cookieName] && cookies[cookieName].analytics === "ok";

        // if (!initialized.current && addTracking) {
        console.info("enabling google tag manager");
        const tagManagerArgs = {
            gtmId: gtmId
        };
        TagManager.initialize(tagManagerArgs);
        // }

        // initialized.current = true;
    };

    return <React.Fragment />;
};
