import { FC } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import salesProgressStyles from "../styles";
import { setProgressColor } from "../helpers-progress";

const useStyles = makeStyles(salesProgressStyles);

interface TitleIconComponentProps {
    isButtons?: boolean;
    title: string;
    progress?: string;
}

const TitleIconComponent: FC<TitleIconComponentProps> = ({ children, title, isButtons, progress = "not-done" }) => {
    const classes = useStyles({ isButtons });

    return (
        <div className={classes.textIconWrapper}>
            <div className={classes.dashedBorderWithIcon} style={{ borderLeft: `2px dashed ${setProgressColor(progress)}` }} />
            <div className={classes.iconWrapper} style={{ backgroundColor: setProgressColor(progress) }}>
                {children}
            </div>
            <Typography className={classes.sectionName}>{title}</Typography>
        </div>
    );
};

export default TitleIconComponent;
