import { FC, useState, useEffect } from "react";
import { RealEstate } from "@onpreo/database/src/definitions/real-estate";
import { Grid, Card, CardContent, Typography, Divider, makeStyles } from "@material-ui/core";
import salesProgressStyles from "@onpreo/components/src/sales-progress/styles";
import { initialProgress, makeProgressBrokerApp } from "@onpreo/components/src/sales-progress/helpers-progress";
import { TimelineList } from "@onpreo/components/src/sales-progress/timeline-list";
import StatusSelect from "@onpreo/components/src/sales-progress/status-select";

const useStyles = makeStyles(salesProgressStyles);

interface SalesProgressProps {
    isButtons?: boolean;
    realEstate: RealEstate;
    onUpdateStatus: any;
    onBuyerEmail?: (realEstateId: string) => void;
}

export const SalesProgress: FC<SalesProgressProps> = ({ isButtons, realEstate, onUpdateStatus, onBuyerEmail }) => {
    const classes = useStyles({ isButtons });
    const [progressType, setProgressType] = useState(initialProgress);
    const [statusValue, setStatusValue] = useState(realEstate?.status || "default");

    const handleChangeStatus = e => {
        const newStatus = e.target.value;
        setStatusValue(newStatus);
        onUpdateStatus(newStatus);
    };

    useEffect(() => {
        setProgressType(makeProgressBrokerApp(realEstate));
    }, [realEstate?.status]);

    return (
        <Card className={classes.detailsCard}>
            <CardContent>
                <Grid container className={classes.detailsHeaderGrid}>
                    <Typography className={classes.detailsHeaderText}>Verkaufsfortschritt</Typography>
                </Grid>
                <Divider className={classes.divider} />

                <Grid container>
                    <Grid item xs={8} className={classes.timelineList}>
                        <TimelineList
                            isButtons={isButtons}
                            progressType={progressType}
                            onUpdateStatus={onUpdateStatus}
                            realEstate={realEstate}
                            onBuyerEmail={onBuyerEmail}
                        />
                    </Grid>

                    {!isButtons && <StatusSelect isButtons={isButtons} statusValue={statusValue} onChange={handleChangeStatus} realEstate={realEstate} />}
                </Grid>
            </CardContent>
        </Card>
    );
};
