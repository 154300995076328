import React, { FC, useState, useEffect, Fragment } from "react";
import { Grid, makeStyles, Select, MenuItem } from "@material-ui/core";
import salesProgressStyles from "../styles";
import { RealEstateStatus } from "@onpreo/database/src/definitions/real-estate-helpers";
import { disabledSelectButtons, salesProgressSelectData } from "../helpers-progress";
import { ControlledSelect } from "../../inputs";

const useStyles = makeStyles(salesProgressStyles);

interface StatusSelectProps {
    isButtons?: boolean;
    statusValue: string;
    onChange: any;
    realEstate: any;
}

const StatusSelect: FC<StatusSelectProps> = ({ isButtons, statusValue, onChange, realEstate }) => {
    const classes = useStyles({ isButtons });
    const [disabledStatuses, setDisabledStatuses] = useState(null);

    // useEffect(() => {
    //     const statuses = disabledSelectButtons(realEstate);

    // setDisabledStatuses({
    //     AGREE_TO_START_PRICE: statuses.AGREE_TO_START_PRICE,
    //     EXPOSE_DATA_OBTAIN: !statuses.AGREE_TO_START_PRICE && !statuses.EXPOSE_DATA_OBTAIN ? false : true,
    //     EXPOSE_APPROVAL_OBTAIN: !statuses.EXPOSE_DATA_OBTAIN && !statuses.EXPOSE_APPROVAL_OBTAIN ? false : true,
    //     EXPOSE_CREATE: !statuses.EXPOSE_APPROVAL_OBTAIN && !statuses.EXPOSE_CREATE ? false : true,
    //     PUT_RE_ON_PORTALS: !statuses.EXPOSE_CREATE && !statuses.PUT_RE_ON_PORTALS ? false : true,
    //     VIEWING_APPOINTMENTS: !statuses.PUT_RE_ON_PORTALS && !statuses.VIEWING_APPOINTMENTS ? false : true,
    //     FEEDBACK_TOUR_ROUND: !statuses.VIEWING_APPOINTMENTS && !statuses.FEEDBACK_TOUR_ROUND ? false : true,
    //     // CREATE_NEW_VISIT_ROUND: true, // not MVP
    //     // MARKETING_ANALYSIS: true, // not MVP
    //     BUYER_SELECTED: !statuses.FEEDBACK_TOUR_ROUND && !statuses.BUYER_SELECTED ? false : true,
    //     // RESERVED: true, // not MVP
    //     // COMPLIANCE_CHECK: true, // not MVP
    //     NOTARY_APPOINTMENT_TO_AGREE: !statuses.BUYER_SELECTED && !statuses.NOTARY_APPOINTMENT_TO_AGREE ? false : true,
    //     NOTARY_APPOINTMENT_PENDING: !statuses.NOTARY_APPOINTMENT_TO_AGREE && !statuses.NOTARY_APPOINTMENT_PENDING ? false : true,
    //     // POST_APPROVAL_DATE_TO_AGREE: true, // not MVP
    //     // POST_APPROVAL_DATE_PENDING: true, // not MVP
    //     CUSTOMER_CANCEL: false,
    //     HANDOVER_DATE_TO_AGREE: !statuses.NOTARY_APPOINTMENT_PENDING && !statuses.HANDOVER_DATE_TO_AGREE ? false : true,
    //     SOLD: !statuses.HANDOVER_DATE_TO_AGREE && !statuses.SOLD ? false : true,
    //     LOST: false
    // });
    // }, []);

    return (
        /*  <Grid item xs={4} className={classes.timelineList}> */
        <Fragment>
            <label htmlFor="realEstateStatus">Immobilienstatus</label>

            <Select
                fullWidth
                name="realEstateStatus"
                value={statusValue}
                onChange={onChange}
                variant={"outlined"}
                style={{ border: "1px solid #DEE2E6", borderRadius: "4px", width: "100%" }}
            >
                <MenuItem value={"default"} disabled hidden>
                    Choose real estate status
                </MenuItem>

                {salesProgressSelectData.map((item, key) => (
                    <MenuItem
                        key={key}
                        value={RealEstateStatus[item.value]}
                        // disabled={disabledStatuses?.[item.value]}
                    >
                        {item.title}
                    </MenuItem>
                ))}
            </Select>
        </Fragment>
        /* </Grid> */
    );
};

export default StatusSelect;
