import { Box, Collapse, Chip, Divider, Drawer, Hidden, IconButton, List, ListItem, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Link } from "../link";
import clsx from "clsx";
import componentStyles from "./style";
import PerfectScrollbar from "react-perfect-scrollbar";
import Avatar from "@material-ui/core/Avatar";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import MenuIcon from "@material-ui/icons/Menu";
import NavigateNext from "@material-ui/icons/NavigateNext";
import { InfoOutlined, InfoRounded } from "@material-ui/icons";
import { StripePlanTier } from "../../../../../apps/broker-app/src/api/util/stripe/stripe-secrets";

const useStyles = makeStyles(componentStyles as any);

interface CommonProps {
    name?: string;
    // NOTE: you can either use miniName or icon, but not both
    // // // if you use both, only the icon will render
    miniName?: string;
    icon?: string | object;
    iconColor?:
        | "Primary"
        | "PrimaryLight"
        | "Error"
        | "ErrorLight"
        | "Warning"
        | "WarningLight"
        | "Info"
        | "InfoLight"
        | "Success"
        | "SuccessLight"
        | "Default";
    // paths when the tab should be selected
    connectedPath?: string[];
    noUserAccess?: boolean; // no access to sales partners if true
    checkWorkspaceAccess?: string; // check access in workspace schema (name of the field)
}

// this generates an anchor (<a href="href">..</a>) link
// this is a link that is sent outside the app
interface HrefProp {
    upgradeToPro?: boolean;
    href?: string;
}

// this generates a Link (<Link to="layout + path">..</Link>) link
// this is a link that is sent inside the app
interface LinkProp {
    path?: string;
    layout?: string;
    component?: FC;
}

interface CollapseProp {
    collapse?: boolean;
    // name of the collapse - needs to be unique
    state?: string;
    // if you have multi level collapses,
    // you need to set this array to all of the
    // collapses you wish to keep open when opening
    // the multi level collapse
    // multiStates: PropTypes.arrayOf(PropTypes.string),
    // views: PropTypes.arrayOf(PropTypes.oneOfType([hrefProp, linkProp])),
    // shows the depth of the current collapse in the multi level collapses
    inner?: number;
}

export interface RouteProps {
    // this is just a title without any action on it
    // you can think of it as a disabled link
    title?: string;
    // this is just a divider line
    divider?: boolean;
    showChip?: boolean;
    restrictedFeature?: string;
}

export type Route = RouteProps & CommonProps & HrefProp & LinkProp & CollapseProp;

interface SidebarProps {
    user?: {
        name?: string;
        imgSrc?: string;
        imgAlt?: string;
    };
    plan?: string;
    realEstate?: React.ReactNode;
    // use this to make the Sidebar open on responsive mode
    openResponsive: boolean;
    // callback for closing the Sidebar on responsive mode
    closeSidebarResponsive?: () => void;
    // this is the input/component that will be rendered on responsive
    // in our demo, we add this input component since the AdminNavbar
    // will not be visible on responsive mode
    input?: ReactNode;
    // this is the dropdown/component that will be rendered on responsive
    // in our demo, it is the same with the dropdown from the AdminNavbar
    // since the AdminNavbar will not be visible on responsive mode
    dropdown?: ReactNode;
    // NOTE: we recommend that your logo has the following dimensions
    // // 135x40 or 487x144 or a resize of these dimensions
    logo: {
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink?: string;
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink?: string;
        // the image src of the logo
        imgSrc: string;
        // the alt for the img
        imgAlt: string;
    };
    // links that will be displayed inside the component
    routes: Route[];
    showNotificationText?: boolean;
    onClick?: () => void;
    guestMenuPreferences?: any;
}

const guestMenuPreferencesMap = {
    Immobilienbewertung: "priceFinder",
    Verkaufsfortschritt: "realEstateStatus",
    "Mein Exposé": "expose",
    "Notar- und Vertragsdaten": "notary",
    Maklerauftrag: "oneClickDeal"
};

export function Sidebar({
    routes,
    logo,
    openResponsive,
    closeSidebarResponsive,
    user,
    plan,
    realEstate,
    showNotificationText,
    onClick,
    guestMenuPreferences
}: SidebarProps) {
    const classes = useStyles();
    const location = useRouter().asPath;
    const [state, setState] = useState({});
    const [openState, setOpenState] = useState(false);
    const [miniActive, setMiniActive] = useState(false);
    const [mouseEnter, setMouseEnter] = useState(false);

    useEffect(() => {
        setState(getCollapseStates(routes));
    }, []);

    const openSidebar = () => {
        setOpenState(true);
    };

    const closeSidebar = (showNotificationText?: boolean, showChip?: boolean) => {
        setOpenState(false);
        if (showNotificationText && showChip) onClick();
    };

    // makes the sidenav normal on hover (actually when mouse enters on it)
    const onMouseEnterSidenav = () => {
        if (miniActive && !mouseEnter) {
            setMiniActive(false);
            setMouseEnter(true);
        }
    };

    // makes the sidenav mini on hover (actually when mouse leaves from it)
    const onMouseLeaveSidenav = () => {
        if (mouseEnter) {
            setMiniActive(true);
            setMouseEnter(false);
        }
    };

    // this creates the intial state of this component based on the collapse routes
    // that it gets through routes
    const getCollapseStates = routes => {
        let initialState = {};
        routes.map(prop => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: getCollapseInitialState(prop.views),
                    ...getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };

    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
    const getCollapseInitialState = routes => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (location.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    };

    // this is used on mobile devices, when a user navigates
    // the sidebar will autoclose
    const handleMenuClose = () => {
        if (window.innerWidth < 1200) {
            // toggleSidenav();
        }
    };

    // creates the links that appear in the left menu / Sidebar
    const createLinks = routes => {
        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null;
            } else if (prop.divider) {
                return <Divider key={key} classes={{ root: classes.divider }} />;
            } else if (prop.title) {
                if (miniActive) {
                    return null;
                }
                return (
                    <Typography key={key} variant="h6" component="h6" classes={{ root: classes.title }}>
                        {prop.title}
                    </Typography>
                );
            } else if (guestMenuPreferences) {
                if (Object.keys(guestMenuPreferencesMap).includes(prop.name) && !guestMenuPreferences[guestMenuPreferencesMap[prop.name]]) return null;
            }
            let textContent = (
                <Box
                    id={`sidebar-tc-${prop.name}`}
                    alignItems={"center"}
                    display={"flex"}
                    style={{ marginLeft: prop.inner ? prop.inner * 16 : 0, width: "100%" }}
                >
                    <Box minWidth="2.25rem" display="flex" alignItems="center">
                        {typeof prop.icon === "string" ? (
                            <Box component="i" className={prop.icon + " " + classes["text" + prop.iconColor]} marginLeft={miniActive ? "-.25rem" : ""} />
                        ) : null}
                        {typeof prop.icon !== "string" ? (
                            <Box style={{ marginLeft: miniActive ? "-.25rem" : "", marginTop: "-.25rem" }} className={classes["text" + prop.iconColor]}>
                                {prop.icon}
                            </Box>
                        ) : null}
                        {prop.icon === undefined && prop.miniName !== undefined ? (
                            <Box component="span" className={classes["text" + prop.iconColor]}>
                                {prop.miniName}
                            </Box>
                        ) : null}
                    </Box>
                    <Box>{miniActive ? null : prop.name}</Box>
                    {showNotificationText && prop.showChip && (
                        <Box style={{ position: "absolute", right: 4, top: 2 }}>
                            <Typography style={{ fontSize: 8, color: "green" }}>neue Buchungen </Typography>
                        </Box>
                    )}
                    {prop.info && (
                        <Box style={{ marginLeft: "auto" }}>
                            <Tooltip title={prop.info}>
                                <InfoOutlined style={{ marginLeft: 6, marginTop: -2 }} />
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            );
            if (prop.collapse) {
                const st = {};
                st[prop["state"]] = !state[prop.state];
                if (prop.multiStates) {
                    prop.multiStates.forEach(item => {
                        st[item] = state[item];
                    });
                }
                return (
                    <Fragment key={key}>
                        <ListItem
                            id={`sidebar-${prop.name}`}
                            component={"a"}
                            href="#mui"
                            onClick={e => {
                                e.preventDefault();
                                setState(st);
                            }}
                            classes={{
                                root: clsx(classes.listItemRoot, {
                                    [classes.listItemRootCollapseActive]: getCollapseInitialState(prop.views)
                                })
                            }}
                        >
                            {textContent}
                            {miniActive ? null : (
                                <Box
                                    component={NavigateNext}
                                    marginLeft="auto"
                                    width="1rem!important"
                                    height="1rem!important"
                                    className={clsx(classes.listItemRootCollapseIcon, {
                                        [classes.listItemRootCollapseActiveIcon]: state[prop.state]
                                    })}
                                />
                            )}
                        </ListItem>
                        <Collapse in={state[prop.state]} unmountOnExit className={classes.collapseRoot}>
                            <List classes={{ root: classes.listRootCollapse }}>{createLinks(prop.views)}</List>
                        </Collapse>
                    </Fragment>
                );
            } else if (prop.href) {
                return (
                    <ListItem
                        id={`sidebar-${prop.name}`}
                        key={key}
                        // component={"a"}
                        onClick={() => closeSidebar(showNotificationText, prop.showChip)}
                        classes={{
                            root: classes.listItemRoot + (prop.upgradeToPro ? " " + classes.listItemRootUpgradeToPro : ""),
                            selected: classes.listItemSelected
                        }}
                        selected={prop.upgradeToPro === true}
                    >
                        <a href={prop.href} target="_blank" rel="noreferrer" style={{ color: "inherit" }}>
                            {textContent}
                        </a>
                    </ListItem>
                );
            } else {
                return (
                    <Link href={showNotificationText && prop.showChip ? `/premium-content?tabValue=2` : prop.path}>
                        <ListItem
                            id={`sidebar-${prop.name}`}
                            key={key}
                            onClick={() => closeSidebar(showNotificationText, prop.showChip)}
                            classes={{
                                root: classes.listItemRoot + (prop.upgradeToPro ? " " + classes.listItemRootUpgradeToPro : ""),
                                selected: classes.listItemSelected
                            }}
                            style={{ border: showNotificationText && prop.showChip ? "1px solid green" : "" }}
                            selected={location === prop.path || prop.upgradeToPro === true || prop.connectedPath?.includes(location)}
                        >
                            {textContent}
                        </ListItem>
                    </Link>
                );
            }
        });
    };
    let logoImage = <img alt={logo.imgAlt} className={classes.logoClasses} src={logo.imgSrc} />;
    let logoObject =
        logo && logo.innerLink ? (
            <Link href={logo.innerLink} className={classes.logoLinkClasses}>
                {logoImage}
            </Link>
        ) : logo && logo.outterLink ? (
            <a href={logo.outterLink} className={classes.logoLinkClasses}>
                {logoImage}
            </a>
        ) : null;

    const desktopObject = (
        <Fragment>
            <Box display="flex" justifyContent={"center"} alignItems="center">
                {miniActive ? null : logoObject}
            </Box>
            {user && (
                <Fragment>
                    <Box display="flex" justifyContent={"center"} alignItems="center" mb={plan ? 1 : undefined}>
                        <div style={{ position: "relative", justifyContent: "center" }}>
                            <Avatar
                                alt={user?.imgAlt ?? "..."}
                                src={user?.imgSrc ?? "/favicon.png"}
                                classes={{
                                    root: classes.avatarWrapper
                                }}
                            />
                            {plan && (
                                <Tooltip title={`Ihre Lizenz:\n ${plan}`}>
                                    <div className={classes.priceChipContainer}>
                                        <Chip className={classes.priceChip} label={<Typography className={classes.priceLabel}>{plan}</Typography>} />
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </Box>
                    <Box display="flex" justifyContent={"center"} alignItems="center">
                        <Typography align="center">{user?.name ?? "insert:username"}</Typography>
                    </Box>
                </Fragment>
            )}
            {realEstate}
            <List classes={{ root: classes.listRoot }}>{createLinks(routes)}</List>
        </Fragment>
    );

    const mobileObject = (
        <Fragment>
            <IconButton color={"primary"} onClick={() => closeSidebar(false)} classes={{ root: classes.menuOpenIcon }}>
                <Box component={MenuOpenIcon} width="2rem!important" height="2rem!important" />
            </IconButton>
            <Box display="flex" justifyContent={"flex-start"} alignItems="center" pl={2}>
                {miniActive ? null : logoObject}
            </Box>
            {user && (
                <Fragment>
                    <Box display="flex" justifyContent={"center"} alignItems="center">
                        <Avatar
                            alt={user?.imgAlt ?? "..."}
                            src={user?.imgSrc ?? "/favicon.png"}
                            classes={{
                                root: classes.avatarWrapper
                            }}
                        />
                    </Box>
                    <Box display="flex" justifyContent={"center"} alignItems="center">
                        <Typography align="center">{user?.name ?? "insert:username"}</Typography>
                    </Box>
                </Fragment>
            )}
            {realEstate}
            <List classes={{ root: classes.listRoot }}>{createLinks(routes)}</List>
        </Fragment>
    );

    return (
        <Fragment>
            <Hidden lgDown>
                <Drawer
                    variant="permanent"
                    anchor="left"
                    open
                    classes={{
                        paper: clsx({ [classes.drawerDockedMiniActive]: miniActive }),
                        docked: clsx({ [classes.drawerPaperMiniActive]: miniActive })
                    }}
                    onMouseEnter={onMouseEnterSidenav}
                    onMouseLeave={onMouseLeaveSidenav}
                >
                    {typeof navigator !== "undefined" && navigator.platform.indexOf("Win") > -1 ? (
                        <PerfectScrollbar>{desktopObject}</PerfectScrollbar>
                    ) : (
                        desktopObject
                    )}
                </Drawer>
            </Hidden>
            <Hidden xlUp>
                <IconButton color={"secondary"} onClick={openSidebar} classes={{ root: classes.menuIcon }}>
                    <Box component={MenuIcon} width="2rem!important" height="2rem!important" />
                </IconButton>
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={openState}
                    onClose={closeSidebarResponsive}
                    ModalProps={{ onBackdropClick: () => closeSidebar(false) }}
                >
                    {typeof navigator !== "undefined" && navigator.platform.indexOf("Win") > -1 ? (
                        <PerfectScrollbar>{mobileObject}</PerfectScrollbar>
                    ) : (
                        mobileObject
                    )}
                </Drawer>
            </Hidden>
        </Fragment>
    );
}
