import React, { FC } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useBackwardTransitionStyles, useForwardTransitionStyles } from "./transition-styles";

const AnimationLoader: FC<{ childKey: number; isForward: boolean }> = props => {
    const forwardClasses = useForwardTransitionStyles(100);
    const backwardClasses = useBackwardTransitionStyles(100);
    let classNames = {};

    const slideForwardClassNames = {
        enter: forwardClasses.slideEnter,
        enterActive: forwardClasses.slideEnterActive,
        exit: forwardClasses.slideExit,
        exitActive: forwardClasses.slideExitActive
    };
    const slideBackClassNames = {
        enter: backwardClasses.slideEnter,
        enterActive: backwardClasses.slideEnterActive,
        exit: backwardClasses.slideExit,
        exitActive: backwardClasses.slideExitActive
    };

    if (props.isForward) {
        classNames = slideForwardClassNames;
    } else {
        classNames = slideBackClassNames;
    }

    return (
        <SwitchTransition>
            <CSSTransition
                key={props.childKey}
                /*@ts-ignore*/
                addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                }}
                classNames={classNames}
            >
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        //overflowX: "hidden",
                        margin: "-16px -30px -16px -30px",
                        padding: "16px 30px 16px 30px"
                    }}
                >
                    <div className={"transition-container"} style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                        {props.children}
                    </div>
                </div>
            </CSSTransition>
        </SwitchTransition>
    );
};

export default AnimationLoader;
