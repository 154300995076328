import { Controller } from "react-hook-form";
import FilledInput, { FilledInputProps } from "@material-ui/core/FilledInput";
import Typography from "@material-ui/core/Typography";
import React, { FC, Fragment } from "react";
import { useFilledInputStyles } from "./styles";

interface ControlledNumberInputProps extends FilledInputProps {
    control: any;
    errors?: boolean;
    name: string;
    required?: boolean;
    label?: string;
}

export const ControlledNumberInput: FC<ControlledNumberInputProps> = ({ control, errors, placeholder, name, label, required, ...props }) => {
    const classes = useFilledInputStyles();

    return (
        <Fragment>
            {label && <Typography style={{ fontWeight: 600, fontSize: "14px" }}>{label}</Typography>}
            <Controller
                rules={{
                    required: required ? "Dieses Feld ist ein Pflichtfeld" : undefined,
                    max: {
                        value: props.inputProps?.max,
                        message: `der maximal mögliche Wert ist ${props.inputProps?.max}`
                    }
                }}
                control={control}
                name={name}
                render={({ field, fieldState: { error } }) => (
                    <div style={{ position: "relative" }}>
                        <FilledInput
                            {...field}
                            {...props}
                            type={"number"}
                            placeholder={placeholder}
                            className={classes.filledInput}
                            required={required}
                            error={!!error}
                            multiline={!!props.minRows}
                            minRows={props.minRows}
                            maxRows={props.minRows}
                            inputProps={{ inputmode: "numeric", pattern: "[0-9]+([.,][0-9]+)?", lang: "de-DE", step: "any", min: 1, ...props.inputProps }}
                        />
                        {error !== undefined && <Typography className={classes.errorMessage}>{error.message}</Typography>}
                    </div>
                )}
            />
        </Fragment>
    );
};
