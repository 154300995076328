export const AccessKeyIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.2844 5.71613L16.9137 3.34551L18.7887 1.47051C18.9109 1.34832 18.9109 1.15051 18.7887 1.02863L18.3469 0.586758C18.2247 0.46457 18.0269 0.46457 17.905 0.586758L8.82031 9.67144C9.14687 9.93269 9.44313 10.2289 9.70438 10.5555L13.9722 6.28769L16.3428 8.65832C16.4034 8.71894 16.4834 8.74957 16.5634 8.74957C16.6434 8.74957 16.7234 8.71894 16.7844 8.65801L19.2844 6.15801C19.4066 6.03582 19.4066 5.83832 19.2844 5.71613Z"
            fill="#393F49"
        />
        <path
            d="M5.31348 9.0625C2.5566 9.0625 0.313477 11.3056 0.313477 14.0625C0.313477 16.8194 2.5566 19.0625 5.31348 19.0625C8.07035 19.0625 10.3135 16.8194 10.3135 14.0625C10.3135 11.3056 8.07035 9.0625 5.31348 9.0625ZM4.68848 15.9375C3.99816 15.9375 3.43848 15.3778 3.43848 14.6875C3.43848 13.9972 3.99816 13.4375 4.68848 13.4375C5.37879 13.4375 5.93848 13.9972 5.93848 14.6875C5.93848 15.3778 5.37879 15.9375 4.68848 15.9375Z"
            fill="#393F49"
        />
    </svg>
);
