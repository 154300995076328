import { AsyncThunk } from "@reduxjs/toolkit";
import { DeepPartial, FieldValues, UseFormReturn, useForm, UseFormProps } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

export const checkDirtyFields = (dirtyFields, allValues) => {
    if (dirtyFields === true && allValues === "default") return;
    if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;

    const keys = Object.keys(dirtyFields);
    if (keys.length === 0) return;

    const newFields = Object.fromEntries(Object.keys(dirtyFields).map(key => [key, checkDirtyFields(dirtyFields[key], allValues[key])]));

    return newFields;
};

export type SaveFormDirtyFieldsThunkArgs<Base> = { id: string | number; value: DeepPartial<Base> };

export type SaveFormDirtyFieldsSelector<Func extends ReturnType<typeof createSaveDirtyFieldsForm>> = Parameters<Func>;

export type SaveDirtyFieldsType<TFieldValues extends FieldValues> = (
    dirtyFields: any,
    ...args: Parameters<ReturnType<UseFormReturn<TFieldValues, any>["handleSubmit"]>>
) => Promise<boolean>;
/**
 * This is a hook factory, that creates hooks that can be used to build one page forms
 * This aims to primarily abstract the retrieval, synchronization and saving of a react-hook-form
 * with the next backend as well as the redux state
 *
 * For a usage example refer to:
 **/
export const createSaveDirtyFieldsForm =
    <Context extends any, Base extends any, Id extends string | number>(
        baseSelector: (c: Context, id?: Id) => Base,
        saveThunk: AsyncThunk<void, SaveFormDirtyFieldsThunkArgs<Base>, any>
    ) =>
    <TFieldValues extends FieldValues = FieldValues>(
        id: Id,
        selector: (b: Base) => TFieldValues,
        // setter: (data: TFieldValues, dirtyFields: any) => DeepPartial<Base>,
        setter: (data: TFieldValues) => DeepPartial<Base>,
        options?: UseFormProps<TFieldValues, any>
    ): UseFormReturn<TFieldValues, any> & { handleSave: SaveDirtyFieldsType<TFieldValues> } => {
        const dispatch = useDispatch();
        const selected = useSelector(
            useCallback(
                s => {
                    const base = baseSelector(s, id);
                    return selector(base);
                },
                [selector, id]
            )
        );

        const formReturn = useForm<TFieldValues>({
            ...options,
            //@ts-ignore
            defaultValues: { ...selected } as DeepPartial<TFieldValues>
        });

        // wraps handleSubmit into a callable that indicates wheter the save happened or not
        const handleSave = useCallback<SaveDirtyFieldsType<TFieldValues>>(
            (dirtyFields, ...args) =>
                new Promise<boolean>(res => {
                    formReturn.handleSubmit(
                        async valid => {
                            const dirtyValues = checkDirtyFields(dirtyFields, valid);
                            await dispatch(saveThunk({ id, value: setter(dirtyValues) }));
                            res(true);
                        },
                        _invalid => res(false)
                    )(...args);
                }),
            [formReturn.handleSubmit, saveThunk, setter, id]
        );

        // Also sync the redex state back into the form state onChange
        useDeepCompareEffect(() => {
            formReturn.reset(selected);
        }, [formReturn.reset, selected]);

        //@ts-ignore
        return { ...formReturn, handleSave };
    };
