import { Typography, Link } from "@material-ui/core";

export const InfoLink = ({ text, href, link }) => {
    return (
        <Typography variant="h5" style={{ color: "inherit" }}>
            {text}{" "}
            <Link href={href} target="_blank" rel="noopener noreferrer" style={{ color: "inherit" }}>
                {link}
            </Link>
        </Typography>
    );
};
