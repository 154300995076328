import { AsyncThunk } from "@reduxjs/toolkit";
import { UnpackNestedValue, DeepPartial, FieldValues, UseFormReturn, useForm, UseFormProps } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

export type SaveFormThunkArgs<Base> = { id: string | number; value: DeepPartial<Base> };

export type SaveFormSelector<Func extends ReturnType<typeof createSaveForm>> = Parameters<Func>;

export type SaveType<TFieldValues extends FieldValues> = (
    ...args: Parameters<ReturnType<UseFormReturn<TFieldValues, any>["handleSubmit"]>>
) => Promise<boolean>;
/**
 * This is a hook factory, that creates hooks that can be used to build one page forms
 * This aims to primarily abstract the retrieval, synchronization and saving of a react-hook-form
 * with the next backend as well as the redux state
 *
 * For a usage example refer to:
 **/
export const createSaveForm =
    <Context extends any, Base extends any, Id extends string | number>(
        baseSelector: (c: Context, id?: Id) => Base,
        saveThunk: AsyncThunk<void, SaveFormThunkArgs<Base>, any>
    ) =>
    <TFieldValues extends FieldValues = FieldValues>(
        id: Id,
        selector: (b: Base) => UnpackNestedValue<TFieldValues>,
        setter: (data: UnpackNestedValue<TFieldValues>) => DeepPartial<Base>,
        options?: UseFormProps<TFieldValues, any>
    ): UseFormReturn<TFieldValues, any> & { handleSave: SaveType<TFieldValues> } => {
        const dispatch = useDispatch();
        const selected = useSelector(
            useCallback(
                s => {
                    const base = baseSelector(s, id);
                    return selector(base);
                },
                [selector, id]
            )
        );

        const formReturn = useForm<TFieldValues>({
            ...options,
            // @ts-ignore: NOTE should be fine here...
            defaultValues: {
                ...selected
            }
        });

        // wraps handleSubmit into a callable that indicates wheter the save happened or not
        const handleSave = useCallback<SaveType<TFieldValues>>(
            (...args) =>
                new Promise<boolean>(res => {
                    formReturn.handleSubmit(
                        async valid => {
                            // @ts-ignore
                            await dispatch(saveThunk({ id, value: setter(valid) }));
                            res(true);
                        },
                        _invalid => res(false)
                    )(...args);
                }),
            [formReturn.handleSubmit, saveThunk, setter, id]
        );

        // Also sync the redex state back into the form state onChange
        useDeepCompareEffect(() => {
            // @ts-ignore
            formReturn.reset(selected);
        }, [formReturn.reset, selected]);

        //@ts-ignore
        return { ...formReturn, handleSave };
    };
