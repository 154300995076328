import { FC, useCallback } from "react";
import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import * as path from "path";

const useStyles = makeStyles((muiTheme: Theme) => ({
    dropZoneContainer: {
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center"
    },
    controlContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    controls: {
        display: "flex",
        justifyContent: "center"
    },
    controllerContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    uploadIcon: {
        color: muiTheme.palette.text.primary,
        width: 64,
        height: 64,
        marginLeft: muiTheme.spacing(2)
    },
    uploadTitle: {
        maxWidth: 400,
        color: muiTheme.palette.text.primary,
        fontSize: 14
    },
    uploadSubtitle: {
        maxWidth: 400,
        color: muiTheme.palette.text.primary,
        fontSize: 14
    }
}));

const FILE_EXTENSIONS = [".pdf", ".jpeg", ".jpg", ".png", ".gif", ".PNG", ".JPG", ".JPEG", ".HEIC"] as const;
export type DropZoneFileExtension = typeof FILE_EXTENSIONS[number];

type DocumentUploadProps = {
    onChange: (file: File) => void;
    fileExtensions?: DropZoneFileExtension[];
    disabled?: boolean;
};

// Very similar to the ImageUploadDialog but without the crop functionality
// and support pdf and image formats
export const DocumentDropzone: FC<DocumentUploadProps> = ({ onChange, fileExtensions = FILE_EXTENSIONS, disabled }) => {
    const classes = useStyles();
    const onDrop = useCallback<DropzoneOptions["onDrop"]>(
        files => {
            files.forEach(f => {
                const extension = path.extname(f.name);
                if (!fileExtensions.includes(extension as any)) {
                    console.log("Du kannst nur Dateien mit den folgenden Dateinendungen hochladen: " + fileExtensions.join(", "));
                    return;
                }
                // only upload if extension matches
                onChange(f);
            });
        },
        [onChange]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    return (
        <div className={classes.dropZoneContainer} {...getRootProps()}>
            <Grid container justifyContent={"space-between"} wrap={"nowrap"} alignItems={"center"} style={{ width: "100%" }}>
                <input {...getInputProps()} disabled={disabled} />
                {isDragActive ? (
                    <Typography>{"Ziehen Sie die Datei hier rein..."}</Typography>
                ) : (
                    <>
                        <Box>
                            <Typography className={classes.uploadTitle} align={"center"}>
                                {"Klicken Sie auf die Fläche um eine Datei auszuwählen oder ziehen Sie diese in das Feld. Unterstützt werden " +
                                    fileExtensions.join(", ")}
                            </Typography>
                            {/* <br /> */}
                            {/* <Typography align={"center"} className={classes.uploadSubtitle}>
                                {"Unterstützte Dateiformate: " + fileExtensions.join(", ")} <br />
                            </Typography> */}
                        </Box>
                        <CloudUpload className={classes.uploadIcon} />
                    </>
                )}
            </Grid>
        </div>
    );
};
