import { Controller } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import React, { FC, Fragment } from "react";
import Rating, { IconContainerProps } from "@material-ui/lab/Rating";

import SentimentVeryDissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfied from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfied from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAlt from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVerySatisfied from "@material-ui/icons/SentimentVerySatisfied";

interface ControlledRatingInputProps {
    control: any;
    errors: boolean;
    name: string;
    required?: boolean;
    label?: string;
}

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfied style={{ width: 48, height: 48 }} />,
        label: "Very Dissatisfied"
    },
    2: {
        icon: <SentimentDissatisfied style={{ width: 48, height: 48 }} />,
        label: "Dissatisfied"
    },
    3: {
        icon: <SentimentSatisfied style={{ width: 48, height: 48 }} />,
        label: "Neutral"
    },
    4: {
        icon: <SentimentSatisfiedAlt style={{ width: 48, height: 48 }} />,
        label: "Satisfied"
    },
    5: {
        icon: <SentimentVerySatisfied style={{ width: 48, height: 48 }} />,
        label: "Very Satisfied"
    }
};

function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

export const ControlledRatingInput: FC<ControlledRatingInputProps> = ({ control, errors, name, label, required, ...props }) => {
    return (
        <Fragment>
            {label && <Typography style={{ fontWeight: 600, fontSize: "14px" }}>{label}</Typography>}
            <Controller
                rules={{ required: required ? "Dieses Field ist ein Pflichtfeld" : undefined }}
                control={control}
                name={name}
                render={({ field }) => (
                    <Fragment>
                        <Rating
                            name="customized-icons"
                            defaultValue={5}
                            getLabelText={(value: number) => customIcons[value].label}
                            IconContainerComponent={IconContainer}
                            {...field}
                        />
                    </Fragment>
                )}
            />
        </Fragment>
    );
};
