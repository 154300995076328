import { createStyles, makeStyles } from "@material-ui/core";

const componentStyles = makeStyles(theme =>
    createStyles({
        appBarRoot: {
            color: "transparent",
            backgroundColor: "transparent"
        },
        headerImg: {
            verticalAlign: "middle",
            borderStyle: "none",
            marginTop: 16,
            marginLeft: 16,
            width: 120
        },
        menuPaper: {
            width: "calc(100% - 2rem)"
        },
        outlineNone: {
            outline: "none!important"
        },
        flexDirectionColumn: {
            [theme.breakpoints.down("md")]: {
                flexDirection: "column"
            }
        },
        toolbar: {
            [theme.breakpoints.down("md")]: {
                justifyContent: "flex-start"
            }
        }
    })
);

export default componentStyles;
