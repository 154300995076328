import { makeStyles, Theme } from "@material-ui/core";

const useImageUploadStyles = makeStyles((muiTheme: Theme) => ({
    root: {
        flexGrow: 1,
        padding: muiTheme.spacing(2)
    },
    cropContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    dialogTitle: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    closeButton: {
        color: muiTheme.palette.grey[500],
        width: 44,
        boxShadow: "none",
        left: muiTheme.spacing(1),
        bottom: muiTheme.spacing(1)
    },
    trashButton: {
        color: muiTheme.palette.grey[500],
        boxShadow: "none"
    },
    saveButton: {
        color: muiTheme.palette.primary.main,
        boxShadow: "none"
    },
    dropZoneContainer: {
        display: "flex",
        height: 280,
        border: "1px solid lightgrey",
        cursor: "pointer",
        padding: 10,

        [muiTheme.breakpoints.down("sm")]: {
            paddingLeft: 20
        }
    },
    controlContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    controls: {
        display: "flex",
        justifyContent: "center"
    },
    controllerContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    uploadIcon: {
        color: muiTheme.palette.text.primary,
        width: 64,
        height: 64
    },
    uploadTitle: {
        maxWidth: 400,
        color: muiTheme.palette.text.primary,
        fontSize: 16
    },
    uploadSubtitle: {
        maxWidth: 400,
        color: muiTheme.palette.text.primary,
        fontSize: 14
    }
}));

export default useImageUploadStyles;
