import boxShadows from "../theme/box-shadow";
import hexToRgb from "../theme/hex-to-rgb";

import { createStyles, Theme } from "@material-ui/core";

// TODO: Obviously we will need to remove all the ts-ignores, we do however would need to adapt the theme type definition for that!
// i mean, really come on material-ui

const componentStyles = (theme: Theme) =>
    createStyles({
        cardRoot: {
            boxShadow: boxShadows.boxShadow + "!important"
        },
        cardRootTranslucent: {
            // @ts-ignore
            backgroundColor: theme.palette.transparent.main
        },
        cardRootStripped: {
            "& tbody tr:nth-child(odd)": {
                // @ts-ignore
                backgroundColor: "rgba(" + hexToRgb(theme.palette.gray[100]) + ", 0.3)"
            }
        },
        cardRootCheckboxColors: {
            "& tbody tr:nth-child(1)": {
                // @ts-ignore
                backgroundColor: theme.palette.success.tableLight,
                "&:hover": {
                    // @ts-ignore
                    backgroundColor: theme.palette.success.tableLightHover
                }
            },
            "& tbody tr:nth-child(3)": {
                // @ts-ignore
                backgroundColor: theme.palette.warning.tableLight,
                "&:hover": {
                    // @ts-ignore
                    backgroundColor: theme.palette.warning.tableLightHover
                }
            }
        },
        cardRootDark: {
            // @ts-ignore
            backgroundColor: theme.palette.dark.main,
            "& *": {
                // @ts-ignore
                color: theme.palette.white.main
            },
            "& $tableCellRoot, & $tableRoot": {
                // @ts-ignore
                color: theme.palette.white.main,
                // @ts-ignore
                borderColor: theme.palette.dark.tableBorder
            },
            "& $tableCellRootHead": {
                // @ts-ignore
                color: theme.palette.dark.tableHeadColor,
                // @ts-ignore
                backgroundColor: theme.palette.dark.tableHeadBgColor
            }
        },
        cardHeader: {
            backgroundColor: "initial"
        },
        cardActionsRoot: {
            paddingBottom: "1.5rem!important",
            paddingTop: "1.5rem!important",
            borderTop: "0!important"
        },
        containerRoot: {
            [theme.breakpoints.up("md")]: {
                paddingLeft: "30px",
                paddingRight: "30px"
            }
        },
        tableRoot: {
            marginBottom: "0!important"
        },
        tableCellRoot: {
            verticalAlign: "middle",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
            borderTop: "0"
        },
        tableCellRootHead: {
            // @ts-ignore
            backgroundColor: theme.palette.gray[100],
            // @ts-ignore
            color: theme.palette.gray[600]
        },
        tableCellRootBodyHead: {
            // @ts-ignore
            textTransform: "unset!important",
            fontSize: ".8125rem"
        },
        linearProgressRoot: {
            height: "3px!important",
            width: "120px!important",
            margin: "0!important"
        },
        bgGradientError: {
            background: "linear-gradient(87deg," + theme.palette.error.main + ",#f56036)!important"
        },
        bgGradientSuccess: {
            background: "linear-gradient(87deg," + theme.palette.success.main + ",#2dcecc)!important"
        },
        bgGradientPrimary: {
            background: "linear-gradient(87deg," + theme.palette.primary.main + ",#825ee4)!important"
        },
        bgGradientInfo: {
            background: "linear-gradient(87deg," + theme.palette.info.main + ",#1171ef)!important"
        },
        bgGradientWarning: {
            background: "linear-gradient(87deg," + theme.palette.warning.main + ",#fbb140)!important"
        },
        bgPrimary: {
            backgroundColor: theme.palette.primary.main
        },
        bgPrimaryLight: {
            backgroundColor: theme.palette.primary.light
        },
        bgError: {
            backgroundColor: theme.palette.error.main
        },
        bgErrorLight: {
            backgroundColor: theme.palette.error.light
        },
        bgWarning: {
            backgroundColor: theme.palette.warning.main
        },
        bgWarningLight: {
            backgroundColor: theme.palette.warning.light
        },
        bgInfo: {
            backgroundColor: theme.palette.info.main
        },
        bgInfoLight: {
            backgroundColor: theme.palette.info.light
        },
        bgSuccess: {
            backgroundColor: theme.palette.success.main
        },
        verticalAlignMiddle: {
            verticalAlign: "middle"
        },
        avatarRoot: {
            width: "36px",
            height: "36px",
            fontSize: ".875rem"
        },
        anchorStyles: {
            fontWeight: 600
        },
        buttonRootWhite: {
            color: theme.palette.primary.main,
            // @ts-ignore
            backgroundColor: theme.palette.white.main,
            // @ts-ignore
            borderColor: theme.palette.white.main,
            "&:hover": {
                // @ts-ignore
                backgroundColor: theme.palette.white.main,
                // @ts-ignore
                borderColor: theme.palette.white.main,
                // @ts-ignore
                color: theme.palette.dark.main
            }
        },
        tableRowNotClicked: {
            // cursor: "auto",
            backgroundColor: "rgb(194 221 194 / 48%)"
        },
        tableRowClicked: {
            cursor: "pointer",
            backgroundColor: "inherit"
        },
        tableRowDefaultBackground: {
            // cursor: "auto",
            backgroundColor: "inherit"
        },
        button: {
            border: "0.1px solid",
            height: 28,
            width: 28,
            postion: "absolute",
            top: 0
        },
        buttonGroup: {
            borderRadius: 5,
            boxShadow: theme.shadows[2]
        }
    });

export default componentStyles;
