import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

export const useHover = <E extends HTMLElement>(): [boolean, MutableRefObject<E>] => {
    const [value, setValue] = useState(false);
    const ref = useRef<E>(null);
    const handleMouseOver = useCallback(() => setValue(true), []);
    const handleMouseOut = useCallback(() => setValue(false), []);
    useEffect(
        () => {
            const node = ref.current;
            if (node) {
                node.addEventListener("mouseover", handleMouseOver);
                node.addEventListener("mouseout", handleMouseOut);
                return () => {
                    node.removeEventListener("mouseover", handleMouseOver);
                    node.removeEventListener("mouseout", handleMouseOut);
                };
            }
        },
        [ref.current] // Recall only if ref changes
    );

    return [value, ref];
};
